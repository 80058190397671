import { useState, useEffect, useContext } from "react";
import Parser from "html-react-parser";
import firebase from "../firebase_exports";
import { useParams } from "react-router-dom";
import { HeaderMenu } from "../components/HeaderBar";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { MiscContext, AuthContext } from "../contexts/Contexts";
import FacebookIcon from "../assets/icons/facebook.png";
import GoogleMapIcon from "../assets/icons/googlemap.png";
import { DetailIcon } from "../assets/icons/Icons";

export const EventPage = () => {
  const { getEvent, isMobile } = useContext(MiscContext);
  const [event, setEvent] = useState({});
  const { id } = useParams();
  const { loggedIn, isValidPaidAccount, setShowUpgradeModal } = useContext(AuthContext);
  const [timestamp, setTimestamp] = useState(new Date());
  const history = useHistory();

  useEffect(() => {
    if (loggedIn && !isValidPaidAccount) {
      setShowUpgradeModal(true);
      history.push("/");
    }
  }, [loggedIn, isValidPaidAccount, setShowUpgradeModal, history]);
  useEffect(() => {
    getEvent(id).then((item) => {
      setEvent(item);
      setTimestamp(
        new firebase.firestore.Timestamp(item.datetime.seconds, item.datetime.nanoseconds).toDate()
      );
    });
  }, [getEvent, id]);
  return (
    <>
      <header>
        <HeaderMenu title={"イベント"} />
      </header>
      <div
        style={
          isMobile
            ? {
                height: "100%",
                paddingTop: 40,
                backgroundColor: "white",
                minHeight: window.innerHeight,
                overflowX: "hidden",
              }
            : {
                marginTop: 100,
                paddingTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }
        }
      >
        {event && (
          <>
            <img
              alt="eventthumbnail"
              src={event.thumbnail && event.thumbnail.src}
              style={{
                width: isMobile ? "100%" : Math.min(window.innerWidth, 564),
                height: (Math.min(window.innerWidth, 564) * 9) / 16,
                objectFit: "cover",
              }}
            />
            <div
              style={{
                textAlign: "left",
                marginTop: 30,
                paddingRight: 24,
                paddingLeft: 24,
                minWidth: Math.min(window.innerWidth, 564),
                width: isMobile ? 400 : 800,
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 700 }}>{event.title}</div>
              <div>日時: {format(timestamp, "yyyy年MM月dd日 HH:mm~")}</div>
              <div
                style={isMobile ? { marginTop: 16, fontSize: 10 } : { marginTop: 16, fontSize: 14 }}
              >
                {event.description && Parser(`<div>${event.description}</div>`)}
              </div>
              <div>
                {event.facebook_url && (
                  <a rel="noreferrer" target="_blank" href={event.facebook_url}>
                    <img
                      alt="facebookicon"
                      src={FacebookIcon}
                      style={{
                        width: "50px",
                        height: "50px",
                        margin: 4,
                        borderRadius: 3,
                      }}
                    />
                  </a>
                )}
                {event.google_map_url && (
                  <a rel="noreferrer" target="_blank" href={event.google_map_url}>
                    <img
                      alt="googlemapicon"
                      src={GoogleMapIcon}
                      style={{
                        width: "50px",
                        height: "50px",
                        margin: 4,
                        marginLeft: 12,
                        borderRadius: 3,
                      }}
                    />
                  </a>
                )}
                {event.detail_url && (
                  <a rel="noreferrer" target="_blank" href={event.detail_url}>
                    <DetailIcon
                      style={{
                        width: "66.67px",
                        height: "58.33px",
                        margin: 0,
                        borderRadius: 3,
                        color: "#535353",
                      }}
                    />
                  </a>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
