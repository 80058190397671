import { firestore } from "../firebase_exports";
import { AuthContext } from "./AuthContext";
import { useState, useContext, useEffect, createContext, useMemo } from "react";

export const LessonsContext = createContext();

export const LessonsProvider = ({ children }) => {
  const { user, isValidPaidAccount } = useContext(AuthContext);
  const [subjectSubscriptions, setSubjectSubscriptions] = useState({ subjects: {} });
  const db = firestore;
  const lessonsCollection = db.collection("lessons");
  const subjectsCollection = db.collection("subjects");
  const subjectSubscriptionsCollection = useMemo(() => db.collection("subject_subscription"), [db]);

  useEffect(() => {
    if (user) {
      subjectSubscriptionsCollection.doc(user.uid).onSnapshot((doc) => {
        setSubjectSubscriptions({ subjects: {}, ...doc.data() });
      });
    } else {
      setSubjectSubscriptions({ subjects: {} });
    }
  }, [user, subjectSubscriptionsCollection]);

  // Havent tested this yet
  const isUserAccessible = async (lesson_id) => {
    try {
      let doc = await lessonsCollection.doc(lesson_id).get();
      let { subject_id } = doc.data();

      let subjectDoc = await subjectsCollection.doc(subject_id).get();

      if (subjectDoc.exists) {
        // Get lesson ids from subjects
        let { lesson_ids } = subjectDoc.data();
        // Find index of the object that has the lesson_id as id
        let target = lesson_ids.filter((item) => item.id === lesson_id);

        // Get index of the target lesson in that subject
        let thisLessonsIndex = target[0].index;
        if (isValidPaidAccount) {
          if (subject_id in subjectSubscriptions.subjects) {
            if (subjectSubscriptions.subjects[subject_id].completed) return true;
            return subjectSubscriptions.subjects[subject_id].index >= thisLessonsIndex;
          } else {
            // Hasnt taken the class yet
            return thisLessonsIndex < 1;
          }
        } else {
          return thisLessonsIndex < 1;
        }
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const passQuiz = (lesson_id) => {
    lessonsCollection
      .doc(lesson_id)
      .get()
      .then((doc) => {
        let subject_id = doc.data().subject_id;
        // There are cases where the subject is not taken yet
        if (isValidPaidAccount) {
          if (subject_id in subjectSubscriptions.subjects) {
            subjectsCollection
              .doc(subject_id)
              .get()
              .then((subjectDoc) => {
                // Get lesson ids from subjects
                let { lesson_ids } = subjectDoc.data();
                // Find index of the object that has the lesson_id as id
                let target = lesson_ids.filter((item) => item.id === lesson_id);

                // Get index of the target lesson in that subject
                let thisLessonsIndex = target[0].index;
                // If user's current index is bigger than lessonIndex + 1, then keep it as is
                subjectSubscriptionsCollection.doc(user.uid).update({
                  subjects: {
                    ...subjectSubscriptions.subjects,
                    [subject_id]: {
                      index:
                        subjectSubscriptions.subjects[subject_id].index > thisLessonsIndex + 1
                          ? subjectSubscriptions.subjects[subject_id].index
                          : thisLessonsIndex + 1,
                    },
                  },
                });
              });
          } else {
            // When there was no entry in the DB, just put 1
            subjectSubscriptionsCollection.doc(user.uid).update({
              subjects: {
                ...subjectSubscriptions.subjects,
                [subject_id]: {
                  index: 1,
                },
              },
            });
          }
        }
      });
  };

  const findSubjectByCategory = async (category) => {
    let tmp = [];
    return subjectsCollection
      .where("category", "==", category)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => tmp.push(doc.data()));
        return tmp;
      })
      .catch((e) => {
        console.error(e);
        return tmp;
      });
  };

  const findSubjectByInstructor = async (instructor_id) => {
    let tmp = [];
    return subjectsCollection
      .where("instructor_id", "==", instructor_id)
      .get()
      .then((querySnapshot) => {
        return querySnapshot.forEach((doc) => tmp.push(doc.data()));
      })
      .catch((e) => {
        console.error(e);
        return tmp;
      });
  };

  const findLessonsByTopic = async (topic) => {
    let tmp = [];
    return lessonsCollection
      .where("topic", "==", topic)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => tmp.push(doc.data()));
        return tmp;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  };

  // topics is an array of string, up to 10
  const findLessonsByMultiTopics = async (topics) => {
    let tmp = [];
    return lessonsCollection
      .where("topic", "in", topics)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => tmp.push(doc.data()));
        return tmp;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  };

  const findSubjectBy = async ({ category, instructor, topic, title }) => {
    const lessonsPromise =
      topic === "" ? Promise.resolve(null) : lessonsCollection.where("topic", "==", topic).get();

    let subjectQuery = subjectsCollection;
    if (category != null && category !== "") {
      subjectQuery = subjectQuery.where("category", "==", category);
    }
    if (instructor !== "") {
      subjectQuery = subjectQuery.where("instructor_id", "==", instructor);
    }
    if (title != null && title !== "") {
      subjectQuery = subjectQuery.orderBy("title").startAt(title).endAt(`${title}\uf8ff`);
    }
    const subjectPromise = subjectQuery.get();

    const [lessonsSnapShot, subjectsSnapShot] = await Promise.all([lessonsPromise, subjectPromise]);
    const subjects = subjectsSnapShot.docs;
    const subjectsList = subjects.map((e) => e.data());
    if (topic === "" || subjects.length === 0) {
      return subjectsList;
    }

    const lessonIds = lessonsSnapShot.docs.map((e) => e.id);
    return subjectsList.filter((e) => e?.lesson_ids?.some((v) => lessonIds.includes(v.id)));
  };

  const getSubject = async (id) => {
    return subjectsCollection
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) return doc.data();
        else return {};
      });
  };

  const getLesson = async (id) => {
    return lessonsCollection
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) return doc.data();
        else return {};
      });
  };

  return (
    <LessonsContext.Provider
      value={{
        subjectSubscriptions,
        findLessonsByTopic,
        findLessonsByMultiTopics,
        findSubjectBy,
        findSubjectByCategory,
        findSubjectByInstructor,
        getSubject,
        getLesson,
        passQuiz,
        isUserAccessible,
      }}
    >
      {children}
    </LessonsContext.Provider>
  );
};
