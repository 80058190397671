import firebase, { firestore } from "../firebase_exports";
import { useState, useEffect, createContext } from "react";

export const MiscContext = createContext();

export const MiscProvider = ({ children }) => {
  const db = firestore;

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [isMobile, setMobile] = useState(false);

  const [announcementsList, setAnnouncementsList] = useState([]);
  const [announcements, setAnnouncements] = useState({});
  const [instructorsList, setLecturersList] = useState([]);
  const [instructors, setLecturers] = useState({});
  const [categories, setCategories] = useState({});
  const [categoriesList, setCategoriesList] = useState([]);
  const [voiceMedium, setVoiceMedium] = useState({});
  const [voiceMediumList, setVoiceMediumList] = useState([]);
  const [topics, setTopics] = useState({});
  const [topicsList, setTopicsList] = useState([]);
  const [highlightedEvents, setHighlightedEvents] = useState([]);
  const [highlightedLiveStreams, setHighlightedLiveStreams] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [news, setNews] = useState({});
  const [adminControls, setAdminControls] = useState({
    highlighted_events: [],
    highlighted_live_streams: [],
  });

  useEffect(() => {
    setMobile(dimensions.width <= 768);
  }, [dimensions.width]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Get stuff from admin controls
  useEffect(() => {
    db.collection("admin_controls")
      .doc("0")
      .onSnapshot((doc) => setAdminControls(doc.data()));
  }, [db]);

  // ---- Announcements ----- {{{
  useEffect(() => {
    const unsubscribe = db
      .collection("announcements")
      .orderBy("created_at", "desc")
      .onSnapshot((snapshot) => {
        let items = snapshot.docs.map((doc) => doc.data());
        let tmp = {};
        snapshot.docs.forEach((doc) => {
          tmp[doc.data().id] = doc.data();
        });
        setAnnouncements(tmp);
        setAnnouncementsList(items);
      });
    return unsubscribe;
  }, [db]);
  // }}}

  // ---- Categories ----- {{{
  useEffect(() => {
    const unsubscribe = db.collection("categories").onSnapshot((snapshot) => {
      let items = snapshot.docs.map((doc) => doc.data());
      let tmp = {};
      snapshot.docs.forEach((doc) => {
        tmp[doc.data().id] = doc.data();
      });
      setCategories(tmp);
      setCategoriesList(items);
    });
    return unsubscribe;
  }, [db]);
  // }}}

  // ---- Topics ----- {{{
  useEffect(() => {
    const unsubscribe = db
      .collection("topics")
      .get()
      .then((snapshot) => {
        let items = snapshot.docs.map((doc) => doc.data());
        let tmp = {};
        snapshot.docs.forEach((doc) => {
          tmp[doc.data().id] = doc.data();
        });
        setTopics(tmp);
        setTopicsList(items);
      });
    return unsubscribe;
  }, [db]);
  // }}}

  // ---- Events ----- {{{
  useEffect(() => {
    setHighlightedEvents(adminControls.highlighted_events);
  }, [adminControls]);

  const listEventsOfCloser = async (date, counts = 10) => {
    const res = await db
      .collection("events")
      .where("datetime", ">=", new firebase.firestore.Timestamp.fromDate(date))
      .orderBy("datetime")
      .limit(counts)
      .get();
    return res.docs;
  };

  const listEventsOf = async ({ begin, end }) => {
    let tmp = [];
    return db
      .collection("events")
      .where("datetime", "<=", new firebase.firestore.Timestamp.fromDate(end))
      .where("datetime", ">=", new firebase.firestore.Timestamp.fromDate(begin))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((item) => tmp.push(item.data()));
        return tmp;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  };
  const listEventsOfDay = async (date) => {
    let tmp = [];
    let nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return db
      .collection("events")
      .where("datetime", "<=", new firebase.firestore.Timestamp.fromDate(nextDay))
      .where("datetime", ">=", new firebase.firestore.Timestamp.fromDate(date))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((item) => tmp.push(item.data()));
        return tmp;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  };
  const getEvent = (id) => {
    return db
      .collection("events")
      .doc(id)
      .get()
      .then((doc) => doc.data())
      .catch((e) => []);
  };
  // }}}

  // ---- Instructors ----- {{{
  useEffect(() => {
    const unsubscribe = db.collection("instructors").onSnapshot((snapshot) => {
      let items = snapshot.docs.map((doc) => doc.data());
      let tmp = {};
      snapshot.docs.forEach((doc) => {
        tmp[doc.data().id] = doc.data();
      });
      setLecturers(tmp);
      setLecturersList(items);
    });
    return unsubscribe;
  }, [db]);
  // }}}

  // ---- LiveStreamEvent ----- {{{
  useEffect(() => {
    setHighlightedLiveStreams(adminControls.highlighted_live_streams);
  }, [adminControls]);

  const listLiveStreamEventsOfCloser = async (date, count) => {
    const res = await db
      .collection("live_stream_events")
      .where("datetime", ">=", new firebase.firestore.Timestamp.fromDate(date))
      .orderBy("datetime")
      .limit(count)
      .get();
    return res.docs;
  };

  const listLiveStreamEventsOf = async ({ begin, end }) => {
    let tmp = [];
    return db
      .collection("live_stream_events")
      .where("datetime", "<=", new firebase.firestore.Timestamp.fromDate(end))
      .where("datetime", ">=", new firebase.firestore.Timestamp.fromDate(begin))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => tmp.push(doc.data()));
        return tmp;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  };
  const listLiveStreamEventsOfDay = async (date) => {
    let tmp = [];
    let nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return db
      .collection("live_stream_events")
      .where("datetime", "<=", new firebase.firestore.Timestamp.fromDate(nextDay))
      .where("datetime", ">=", new firebase.firestore.Timestamp.fromDate(date))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => tmp.push(doc.data()));
        return tmp;
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  };

  const getLiveStream = (id) => {
    return db
      .collection("live_stream_events")
      .doc(id)
      .get()
      .then((doc) => doc.data())
      .catch((e) => []);
  };
  // }}}

  // ---- VoiceMedium ----- {{{
  useEffect(() => {
    const unsubscribe = db
      .collection("voice_media")
      .orderBy("created_at", "desc")
      .onSnapshot((snapshot) => {
        let items = snapshot.docs.map((doc) => doc.data());
        let tmp = {};
        snapshot.docs.forEach((doc) => {
          tmp[doc.data().id] = doc.data();
        });
        setVoiceMedium(tmp);
        setVoiceMediumList(items);
      });
    return unsubscribe;
  }, [db]);
  // }}}

  // ---- News ----- {{{
  useEffect(() => {
    const unsubscribe = db
      .collection("news")
      .orderBy("created_at", "desc")
      .onSnapshot((snapshot) => {
        let items = snapshot.docs.map((doc) => doc.data());
        let tmp = {};
        snapshot.docs.forEach((doc) => {
          tmp[doc.data().id] = doc.data();
        });
        setNews(tmp);
        setNewsList(items);
      });
    return unsubscribe;
  }, [db]);
  // }}}

  return (
    <MiscContext.Provider
      value={{
        announcements,
        announcementsList,
        categories,
        categoriesList,
        topics,
        topicsList,
        voiceMedium,
        voiceMediumList,
        instructors,
        instructorsList,
        newsList,
        news,
        highlightedEvents,
        highlightedLiveStreams,
        listEventsOf,
        listEventsOfCloser,
        listEventsOfDay,
        listLiveStreamEventsOf,
        listLiveStreamEventsOfCloser,
        listLiveStreamEventsOfDay,
        getEvent,
        getLiveStream,
        dimensions,
        isMobile,
      }}
    >
      {children}
    </MiscContext.Provider>
  );
};
