import { useEffect, useState } from "react";
import { getDaysInMonth } from "date-fns";

const dropdownStyle = {
  borderRadius: 3,
  backgroundColor: "white",
  border: "1px solid lightgray",
  padding: 4,
};

export const DateInputDropdown = ({ date, setDate }) => {
  console.log(date);
  const years = [...Array(150).keys()].map((item) => item + new Date().getFullYear() - 100);
  const months = [...Array(12).keys()].map((item) => item + 1);
  const [year, setYear] = useState(date?.slice(0, 4) || new Date().getFullYear() - 25);
  const [month, setMonth] = useState(Number(date?.slice(5, 7)) || new Date().getMonth() + 1);
  const [day, setDay] = useState(Number(date?.slice(8, 10)) || new Date().getDate());
  const [days, setDays] = useState(
    [...Array(getDaysInMonth(new Date(year, month - 1))).keys()].map((item) => item + 1)
  );

  useEffect(() => {
    let tmp = [...Array(getDaysInMonth(new Date(year, month - 1))).keys()].map((item) => item + 1);
    if (day > tmp[tmp.length - 1]) {
      setDay(tmp[tmp.length - 1]);
    }
    setDays(tmp);
  }, [year, month, day]);

  useEffect(() => {
    setDate(`${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`);
    // eslint-disable-next-line
  }, [year, month, day]);

  return (
    <div>
      <select
        style={dropdownStyle}
        id="year"
        name="year"
        onChange={(e) => setYear(e.target.value)}
        value={year}
      >
        {years.map((year) => (
          <option value={year}>{year}</option>
        ))}
      </select>
      <select
        style={dropdownStyle}
        id="month"
        name="month"
        onChange={(e) => setMonth(e.target.value)}
        value={month}
      >
        {months.map((month) => (
          <option value={month}>{month}</option>
        ))}
      </select>
      <select
        style={dropdownStyle}
        id="day"
        name="day"
        onChange={(e) => setDay(e.target.value)}
        value={day}
      >
        {days.map((day) => (
          <option value={day}>{day}</option>
        ))}
      </select>
    </div>
  );
};
