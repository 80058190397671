import { useState, useContext, useEffect, useRef } from "react";
import { Title } from "../components/Title";
import { Search } from "../components/Search";
import { DropDownList } from "../components/DropDownList";
import { useHistory, Link } from "react-router-dom";
import { MiscContext } from "../contexts/Contexts";
import { CategoryItem } from "../components/FindSubjectItems";

export const CategoryListPage = () => {
  // From Contexts
  const { categoriesList, instructorsList, topicsList } = useContext(MiscContext);
  const history = useHistory();

  // State for this page
  const [searchedTitle, setSearchedTitle] = useState("");
  const [instructor, setInstructor] = useState({ value: "" });
  const [topic, setTopic] = useState({ value: "" });
  const genre = "学部";
  const { isMobile } = useContext(MiscContext);
  const isFirst = useRef(true);

  useEffect(() => {
    console.debug(isFirst.current, instructor.value, topic.value, searchedTitle);
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }
    const query = new URLSearchParams();
    query.set("instructor", instructor.value);
    query.set("topic", topic.value);
    query.set("title", searchedTitle);

    history.push({
      pathname: "/find_subjects",
      search: query.toString(),
    });
  }, [history, topic, instructor, searchedTitle]);

  // 1画面に収まるサイズで講義要素のデスクトップ時のスタイルを取得する
  const categoryDesktopStyle = (() => {
    // 講義一覧を表示する領域の縦横サイズを取得
    const categoryListAreaMargin = 72 * 2;
    const headerHeight = 490;
    const categoryListAreaWidth = window.innerWidth - categoryListAreaMargin;
    const categoryListAreaHeight = window.innerHeight - headerHeight;

    // 講義ごとの縦横サイズを計算して取得
    const horizontalCategoryCount = Math.ceil(categoriesList.length / 2);
    const verticalCategoryCount = 2;
    const categoryWidthMargin = 20;
    const categoryHeightMargin = 20;
    const categoryWidth = categoryListAreaWidth / horizontalCategoryCount - categoryWidthMargin;
    const categoryHeight = categoryListAreaHeight / verticalCategoryCount - categoryHeightMargin;

    // デフォルトの横幅より小さければフォントサイズも小さくする
    const defaultCategoryWidth = 220;
    const fontSize = categoryWidth > defaultCategoryWidth ? 20 : 12;

    return {
      width: categoryWidth,
      height: categoryHeight,
      fontSize: fontSize
    }
  })();
  return (
    <>
      <div style={{ paddingTop: isMobile ? 40 : 120 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: isMobile ? 24 : 72,
            marginRight: isMobile ? 24 : 72,
          }}
        >
          {!isMobile && (
            <div>
              <Title title={genre} style={isMobile ? {} : { fontSize: 32 }} />
            </div>
          )}
          <div style={isMobile ? { width: "100%" } : {}}>
            <Search
              placeholder="学科を検索"
              style={isMobile && { width: "100%" }}
              value={searchedTitle}
              onChange={setSearchedTitle}
            />
          </div>
        </div>
        {isMobile && (
          <div style={{ marginLeft: 24, marginBottom: 6 }}>
            <Title title={genre} />
          </div>
        )}
        <div
          style={{
            display: "flex",
            marginLeft: isMobile ? 16 : 72,
            marginRight: isMobile ? 24 : 72,
          }}
        >
          <div>
            <DropDownList
              options={[
                { label: "講師を選択", value: "" },
                ...instructorsList.map((item) => ({ label: item.full_name, value: item.id })),
              ]}
              values={instructor}
              onChange={setInstructor}
              searchBy="full_name"
              labelField="full_name"
              valueField="id"
              clearable={true}
              placeholder="講師を選択"
            />
          </div>
          <div>
            <DropDownList
              options={[
                { label: "トピックを選択", value: "" },

                ...topicsList.map((item) => ({ label: item.name, value: item.id })),
              ]}
              values={topic}
              onChange={setTopic}
              placeholder="トピックを選択"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            marginTop: isMobile ? 20 : 60,
            marginLeft: isMobile ? 16 : 72,
            marginRight: isMobile ? 16 : 72,
          }}
        >
          {categoriesList.map((v) => (
            <div style={{ marginBottom: isMobile ? 20 : 100 }}>
              <Link to={`/categories/${v.id}`} style={{ textDecoration: "none", color: "black" }}>
                <CategoryItem
                  {...v}
                  isMobile={isMobile}
                  key={v.id}
                  width={categoryDesktopStyle.width}
                  height={categoryDesktopStyle.height}
                  fontSize={categoryDesktopStyle.fontSize}/>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
