import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { MiscContext } from "../contexts/Contexts";
import { web } from "../constants/colors";

const LinkStringStyle = {
  mobile: {
    primary: {
      color: web.primary,
      fontSize: 14,
    },
    secondary: {
      color: "white",
      fontSize: 14,
      textDecoration: "none",
    },
  },
  web: {
    primary: {
      color: web.primary,
      fontSize: 14,
    },
    secondary: {
      color: web.secondary,
      fontSize: 14,
      textDecoration: "none",
    },
  },
};

export const LinkString = ({ link, value, style, scheme = "primary" }) => {
  const { isMobile } = useContext(MiscContext);
  return (
    <div>
      <Link
        to={link}
        style={
          isMobile
            ? { ...LinkStringStyle.mobile[scheme], ...style }
            : { ...LinkStringStyle.web[scheme], ...style }
        }
      >
        {value}
      </Link>
    </div>
  );
};
