import { LinkString } from "../../components/LinkString";
import { useContext } from "react";
import { AuthContext } from "../../contexts/Contexts";
import { HeaderMenu } from "../../components/HeaderBar";
import { web } from "../../constants/colors";

export const SettingsPage = () => {
  const { logout } = useContext(AuthContext);

  return (
    <>
      <header>
        <HeaderMenu title={"設定"} />
      </header>
      <div
        style={{
          margin: 0,
          padding: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: web.primary,
        }}
      >
        <div style={{ marginLeft: 24, paddingTop: 50 }}>
          <div>
            <LinkString scheme="secondary" value="運営SNS" link="/snsacess" />
          </div>
          <div style={{ marginTop: 16 }}>
            <a
              href="https://line.me/R/ti/p/@nambara?from=page"
              style={{ textDecoration: "none", color: "white", fontSize: 14 }}
              target="_blank"
              rel="noopener noreferrer"
            >
              お問い合わせ
            </a>
          </div>
          <div style={{ marginTop: 16 }}>
            <a
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", color: "white", fontSize: 14 }}
              href="https://www.encoln.com/%E8%A4%87%E8%A3%BD-%E9%81%8B%E5%96%B6%E4%BC%9A%E7%A4%BE%E6%83%85%E5%A0%B1"
            >
              特商法
            </a>
          </div>
          <div style={{ marginTop: 16 }}>
            <a
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", color: "white", fontSize: 14 }}
              href="https://www.encoln.com/%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E4%BF%9D%E8%AD%B7%E6%B3%95"
            >
              個人情報取り扱い規約
            </a>
          </div>
          <div style={{ marginTop: 16 }}>
            <a
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", color: "white", fontSize: 14 }}
              href="https://www.encoln.com/%E8%A4%87%E8%A3%BD-%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E3%81%AE%E5%8F%96%E3%82%8A%E6%89%B1%E3%81%84"
            >
              利用規約
            </a>
          </div>
          <div style={{ marginTop: 16 }}>
            <a
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", color: "white", fontSize: 14 }}
              href="https://www.encoln.com/post/lets-still-learn"
            >
              退学検討者はこちら
            </a>
          </div>
          <div style={{ color: "white", marginTop: 16 }} onClick={() => logout()}>
            ログアウト
          </div>
        </div>
      </div>
    </>
  );
};

export const SNSAceessPage = () => {
  return (
    <>
      <header>
        <HeaderMenu title={"設定"} />
      </header>
      <div
        style={{
          margin: 0,
          padding: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: web.primary,
        }}
      >
        <div style={{ paddingTop: 8 }}>
          <ul style={{ listStyle: "none" }}>
            <li>
              <a
                style={{ color: "white", fontSize: 14, textDecoration: "none" }}
                href="https://www.encoln.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                WEBサイト
              </a>
            </li>
            <li style={{ marginTop: 16 }}>
              <a
                style={{ color: "white", fontSize: 14, textDecoration: "none" }}
                href="https://line.me/R/ti/p/@nambara?from=page"
                target="_blank"
                rel="noopener noreferrer"
              >
                公式LINE
              </a>
            </li>
            <li style={{ marginTop: 16 }}>
              <a
                style={{ color: "white", fontSize: 14, textDecoration: "none" }}
                href="https://www.facebook.com/groups/encolncom/?ref=share"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li style={{ marginTop: 16 }}>
              <a
                style={{ color: "white", fontSize: 14, textDecoration: "none" }}
                href="https://www.instagram.com/nambaratatsuki/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li style={{ marginTop: 16 }}>
              <a
                style={{ color: "white", fontSize: 14, textDecoration: "none" }}
                href="https://twitter.com/nambaratatsuki"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li style={{ marginTop: 16 }}>
              <a
                style={{ color: "white", fontSize: 14, textDecoration: "none" }}
                href="https://www.youtube.com/channel/UCPAm9wx6kWdUeUO9PsYmE2Q"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
