import { createSvgIcon } from "@material-ui/core/utils";

export default createSvgIcon(
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.59243 14.1759C5.45197 13.3164 6.61776 12.8335 7.83333 12.8335H15.1667C16.3822 12.8335 17.548 13.3164 18.4076 14.1759C19.2671 15.0355 19.75 16.2013 19.75 17.4168V19.2502C19.75 19.7564 19.3396 20.1668 18.8333 20.1668C18.3271 20.1668 17.9167 19.7564 17.9167 19.2502V17.4168C17.9167 16.6875 17.6269 15.988 17.1112 15.4723C16.5955 14.9566 15.896 14.6668 15.1667 14.6668H7.83333C7.10399 14.6668 6.40452 14.9566 5.88879 15.4723C5.37306 15.988 5.08333 16.6875 5.08333 17.4168V19.2502C5.08333 19.7564 4.67293 20.1668 4.16667 20.1668C3.66041 20.1668 3.25 19.7564 3.25 19.2502V17.4168C3.25 16.2013 3.73289 15.0355 4.59243 14.1759Z"
      fill="#FCFCFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.25 19.14C3.25 18.5325 3.74249 18.04 4.35 18.04H18.65C19.2575 18.04 19.75 18.5325 19.75 19.14C19.75 19.7476 19.2575 20.24 18.65 20.24H4.35C3.74249 20.24 3.25 19.7476 3.25 19.14Z"
      fill="#FCFCFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4998 3.66683C9.98105 3.66683 8.74984 4.89805 8.74984 6.41683C8.74984 7.93561 9.98105 9.16683 11.4998 9.16683C13.0186 9.16683 14.2498 7.93561 14.2498 6.41683C14.2498 4.89805 13.0186 3.66683 11.4998 3.66683ZM6.9165 6.41683C6.9165 3.88552 8.96853 1.8335 11.4998 1.8335C14.0311 1.8335 16.0832 3.88552 16.0832 6.41683C16.0832 8.94813 14.0311 11.0002 11.4998 11.0002C8.96853 11.0002 6.9165 8.94813 6.9165 6.41683Z"
      fill="#FCFCFF"
    />
  </>
);
