import firebase from "../firebase_exports";
import Parser from "html-react-parser";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { MiscContext, AuthContext } from "../contexts/Contexts";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { HeaderMenu } from "../components/HeaderBar";

export const LiveStreamPage = () => {
  const { getLiveStream, isMobile } = useContext(MiscContext);
  const [liveStream, setLiveStream] = useState({});
  const [timestamp, setTimestamp] = useState(new Date());
  const { id } = useParams();
  const { loggedIn, isValidPaidAccount, setShowUpgradeModal } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (loggedIn && !isValidPaidAccount) {
      setShowUpgradeModal(true);
      history.push("/");
    }
  }, [loggedIn, isValidPaidAccount, setShowUpgradeModal, history]);
  useEffect(() => {
    getLiveStream(id).then((item) => {
      setLiveStream(item);
      setTimestamp(
        new firebase.firestore.Timestamp(item.datetime.seconds, item.datetime.nanoseconds).toDate()
      );
    });
  }, [getLiveStream, id]);

  return (
    <>
      <header>
        <HeaderMenu title={"LIVE配信"} />
      </header>
      <div
        style={
          isMobile
            ? {
                height: "100%",
                paddingTop: 40,
                backgroundColor: "white",
                minHeight: window.innerHeight,
              }
            : {
                marginTop: 100,
                paddingTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }
        }
      >
        <div>
          <iframe
            width={Math.min(window.innerWidth, 564)}
            height={(Math.min(window.innerWidth, 564) / 16) * 9}
            src={`https://www.youtube.com/embed/${liveStream.youtube_id}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div
          style={{
            paddingRight: 24,
            paddingLeft: 24,
            textAlign: "right",
            minWidth: Math.min(window.innerWidth, 564),
          }}
        >
          <a
            href={`https://youtube.com/watch?v=${liveStream.youtube_id}`}
            style={{ textDecoration: "underline", color: "#919191" }}
          >
            YouTubeで見る
          </a>
        </div>
        <div
          style={{
            textAlign: "left",
            marginTop: 30,
            paddingRight: 24,
            paddingLeft: 24,
            minWidth: Math.min(window.innerWidth, 564),
          }}
        >
          <div style={{ fontSize: 16, fontWeight: 700 }}>{liveStream.title}</div>
          <div>日時: {format(timestamp, "yyyy年MM月dd日 HH:mm~")}</div>
          <div style={isMobile ? { marginTop: 16, fontSize: 10 } : { marginTop: 16, fontSize: 14 }}>
            {liveStream.description && Parser("<div>" + liveStream.description + "</div>")}
          </div>
        </div>
      </div>
    </>
  );
};
