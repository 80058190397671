import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Parser from "html-react-parser";
import { format, differenceInHours } from "date-fns";
import firebase from "../../firebase_exports";
import { Title } from "../../components/Title";
import { HeaderMenu } from "../../components/HeaderBar";
import { MiscContext } from "../../contexts/Contexts";
import AnnouncementIcon from "../../assets/images/ENCOLN_S.png";
import { WebBlock } from "../../components/WebBlock";
import { NewsItem } from "../../components/NewsItem";

export const NewsPage = () => {
  const { isMobile, newsList } = useContext(MiscContext);
  const now = new Date();
  let timestampConverter = (firebaseTimestamp) => {
    return new firebase.firestore.Timestamp(
      firebaseTimestamp.seconds,
      firebaseTimestamp.nanoseconds
    ).toDate();
  };
  return (
    <>
      {isMobile ? (
        <>
          <HeaderMenu title={"ニュース"} />
          <div
            style={{
              backgroundColor: "#F1F1FA",
              margin: 0,
              padding: 0,
              width: "100vw",
              maxWidth: 564,
              paddingTop: 50,
            }}
          >
            {newsList.map((news) => (
              <div
                style={{
                  color: "#161719",
                  paddingTop: 8,
                  marginLeft: 24,
                  marginRight: 24,
                  borderBottom: "1px solid rgba(196, 196, 196, 0.6)",
                  height: 40,
                  overflowY: "hidden",
                }}
                key={news.id}
              >
                <Link
                  to={`/news/${news.id}`}
                  style={{ color: "#161719", textDecoration: "none", fontSize: 12 }}
                >
                  <span>
                    <strong>{format(timestampConverter(news.created_at), "yy.MM.dd")}</strong>
                  </span>
                  {differenceInHours(now, timestampConverter(news.created_at)) < 24 * 1 && (
                    <>
                      &nbsp;
                      <span
                        style={{
                          backgroundColor: "red",
                          fontSize: 10,
                          fontWeight: 700,
                          color: "white",
                          borderRadius: 5,
                          paddingLeft: 5,
                          paddingRight: 5,
                          paddingTop: 1,
                          paddingBottom: 1,
                        }}
                      >
                        NEW
                      </span>
                      &nbsp;
                    </>
                  )}
                  <span>
                    <strong>{news.title}</strong>
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <WebBlock secondary style={{ marginTop: 108 }}>
            <div style={{ marginTop: 88, fontSize: 36, fontWeight: 700, marginBottom: 114 }}>
              ニュース一覧
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {newsList.map((item) => {
                return (
                  <NewsItem
                    date={format(
                      new firebase.firestore.Timestamp(
                        item.created_at.seconds,
                        item.created_at.nanoseconds
                      ).toDate(),
                      "作成日時: yyyy年MM月dd日"
                    )}
                    objectFit={item.image ? "cover" : "contain"}
                    title={item.title}
                    link={`/news/${item.id}`}
                    imgsrc={item.image?.src || AnnouncementIcon}
                  />
                );
              })}
            </div>
          </WebBlock>
        </>
      )}
    </>
  );
};

export const NewsDetailsPage = () => {
  const { isMobile } = useContext(MiscContext);
  const { id } = useParams();
  const { news } = useContext(MiscContext);
  const timestamp = new firebase.firestore.Timestamp(
    news[id].created_at.seconds,
    news[id].created_at.nanoseconds
  ).toDate();

  // TODO do something about the google maps and google calendar

  return (
    <>
      <header>
        <HeaderMenu title={"ニュース"} />
      </header>
      <div
        style={{
          paddingTop: isMobile ? 40 : 120,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingLeft: isMobile ? 0 : "20%",
          paddingRight: isMobile ? 0 : "20%",
        }}
      >
        <img
          src={news[id].image && news[id].image.src}
          style={{
            width: "100%",
            height: isMobile ? "auto" : (Math.min(window.innerWidth, 568) * 9) / 16,
            objectFit: "contain",
          }}
          alt={news[id].image && news[id].image.title}
        ></img>
        <div
          style={{
            marginTop: 30,
            width: Math.min(window.innerWidth, 374),
            paddingLeft: isMobile ? "10%" : 0,
            paddingRight: isMobile ? "10%" : 0,
          }}
        >
          <div>
            <Title title={news[id].title} />
            <p>{format(timestamp, "yyyy年MM月dd日 HH:mm")}</p>
            <p style={{ marginTop: 16 }}>{news[id].description && Parser(news[id].description)}</p>
          </div>
        </div>
      </div>
    </>
  );
};
