import { eachDayOfInterval, getDate, format } from "date-fns/fp";
import { web } from "../constants/colors";
import { isSameDay } from "date-fns";

const schedule = {
  paddingTop: 8,
  fontSize: 40,
  marginRight: 20,
};

const target = {
  common: {
    paddingTop: 8,
    fontSize: 40,
    color: "#ffffff",
    borderRadius: 10,
    width: 40,
    height: 54,
    marginRight: 20,
    background: web.primary,
  },
};

export const Calendar = ({ value, onChange, isMobile, interval, highlightDays }) => {
  console.debug(highlightDays);
  const dateRange = eachDayOfInterval(interval);
  const formatDayOfWeek = format("EEE");
  const formatMonthYear = format("MMM yyyy");
  return (
    <>
      <div style={{ position: "absolute", marginTop: 5, marginLeft: 40 }}>
        <p style={{ fontSize: 32, fontWeight: 500, color: "#212525", marginLeft: 8 }}>
          {getDate(value)}
        </p>
      </div>
      <div>
        <div>
          <p style={{ position: "absolute", marginLeft: 90, marginTop: 8, color: "#BCC1CD" }}>
            {formatDayOfWeek(value)}
          </p>
          <p style={{ position: "absolute", marginLeft: 90, marginTop: 25, color: "#BCC1CD" }}>
            {formatMonthYear(value)}
          </p>
        </div>
      </div>
      <div style={{ marginTop: 60 }}>
        <ul style={{ display: "flex", listStyle: "none", justifyContent: "center" }}>
          {dateRange.map((day) => (
            <div key={day} onClick={() => onChange(day)}>
              <li style={isSameDay(day, value) ? target.common : schedule}>
                <div style={{ textAlign: "center" }}>
                  <div style={{ fontSize: 12, color: "#BCC1CD" }}>{format("EEEEE")(day)}</div>
                  <div style={{ fontSize: 16 }}>
                    <b>{getDate(day)}</b>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      color: "orange",
                      fontSize: "10px",
                      textAlign: "center",
                      height: "10px",
                    }}
                  >
                    {highlightDays.some((e) => isSameDay(e, day)) && "●"}
                  </div>
                </div>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </>
  );
};
