import { useState, useEffect, useContext } from "react";
import Parser from "html-react-parser";
import { Title } from "../components/Title";
import { HeaderMenu } from "../components/HeaderBar";
import { Button } from "../components/Button";
import { useHistory, useParams } from "react-router-dom";
import { Problem } from "../components/Problem";
import { LessonsContext, MiscContext } from "../contexts/Contexts";
import PassImg from "../assets/images/pass_test.png";
import FailImg from "../assets/images/fail_test.png";

export const LessonPage = () => {
  const { id } = useParams();
  const [lesson, setLesson] = useState({});
  const [problems, setProblems] = useState([]);
  const { getLesson, passQuiz } = useContext(LessonsContext);
  const { isMobile, dimensions } = useContext(MiscContext);
  const [answers, setAnswers] = useState([]);
  // const { getLesson, passQuiz } = useContext(LessonsContext);

  useEffect(() => {
    const setupLesson = async () => {
      getLesson(id).then((item) => {
        setLesson(item);
        setProblems(item.problems || []);
        setAnswers(new Array(item.problems?.length) || []);
      });
    };
    setupLesson();
  }, [getLesson, id]);

  const updateAnswer = (index) => (answer) => {
    const tmp = [...answers];
    tmp.splice(index, 1, answer);
    setAnswers(tmp);
  };

  const isSuccess = () => {
    if (answers.length > 0) {
      if (answers[0] != null) {
        answers.every((answer, i) => console.log(answer === problems[i].correct));
        return answers.every((answer, i) => answer === problems[i].correct);
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const [isResultOpen, setIsResultOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const success = () => {
    // Is this function doing too much??
    passQuiz(id);

    // Apparently this does not take to the SubjectPage
    history.push(`/subjects/${lesson.subject_id}`);
  };

  return (
    <>
      <header>
        <HeaderMenu title={""} />
      </header>
      <div
        style={
          isMobile
            ? {
                height: "100%",
                paddingTop: 40,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: window.innerHeight,
                marginLeft: 24,
                marginRight: 24,
              }
            : {
                marginTop: 100,
                paddingTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }
        }
      >
        <div>
          <iframe
            style={{
              boxShadow: "0 6px 10px #999999",
              width: Math.min(dimensions.width, 564),
              height: (Math.min(dimensions.width, 564) / 16) * 9,
            }}
            src={`https://www.youtube.com/embed/${lesson.youtube_id}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        {!isOpen ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: 24,
              marginRight: 24,
              width: "100%",
              maxWidth: 564,
            }}
          >
            <div
              style={{
                maxWidth: 564,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <div>
                <Button
                  style={{
                    width: 145,
                    height: 20,
                    fontSize: 10,
                    borderRadius: 5,
                    boxShadow: "0 3px 3px #cccccc",
                  }}
                  label="テストを受ける"
                  onClick={() => setIsOpen(true)}
                />
              </div>
              <div>
                <a
                  style={{ fontSize: 12, color: "#373E4D" }}
                  href={`https://www.youtube.com/watch?v=${lesson.youtube_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTubeで見る
                </a>
              </div>
            </div>
            <div style={{ marginTop: 16 }}>
              <Title title={lesson.title} />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 8,
                fontSize: isMobile ? 10 : 14,
                textAlign: "left",
              }}
            >
              {lesson.description && Parser("<div>" + (lesson.description || "") + "</div>")}
            </div>
          </div>
        ) : (
          <div
            style={{
              marginLeft: 24,
              marginRight: 24,
              width: "100%",
              fontSize: 24,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{ position: "relative", right: Math.min(dimensions.width, 568) / 2 - 20 }}
              onClick={() => {
                setIsOpen(false);
                setIsResultOpen(false);
              }}
            >
              ×
            </div>
            {!isResultOpen ? (
              <div
                style={{
                  width: `${dimensions.width - 48}`,
                  maxWidth: 568,
                }}
              >
                {problems.length > 0 ? (
                  problems.map((problem, i) => (
                    <Problem
                      isMobile={isMobile}
                      value={{ title: `問題${i + 1}`, ...problem }}
                      answer={answers[i]}
                      updateAnswer={updateAnswer(i)}
                      key={i}
                    />
                  ))
                ) : (
                  <div style={{ fontSize: 18, marginBottom: 10 }}>
                    この講義にテストはありません! 提出を押して次へ進んで下さい。
                  </div>
                )}
                <Button
                  onClick={() => setIsResultOpen(true)}
                  style={{
                    width: "100%",
                  }}
                  label={"提出"}
                />
              </div>
            ) : isSuccess() ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignText: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: 700, fontSize: 40 }}>合格</div>
                <div style={{ fontSize: 14, fontWeight: 700, marginTop: 11 }}>
                  おめでとうございます。
                </div>
                <div style={{ fontSize: 14, fontWeight: 700, marginBottom: 5 }}>
                  次の動画を視聴できるようになりました。
                </div>
                <img height="178px" width="178px" src={PassImg} alt={"passed"} />
                <Button
                  style={{
                    width: "100%",
                    marginTop: 8,
                  }}
                  onClick={() => success()}
                  label="OK"
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignText: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: 700, fontSize: 40 }}>もう一度</div>
                <div style={{ fontSize: 14, fontWeight: 700, marginTop: 11, marginBottom: 24 }}>
                  間違えたところを見直してもう一度挑戦しよう！
                </div>
                <img height="178px" width="178px" src={FailImg} alt={"failed"} />
                <Button
                  style={{ width: "100%", marginTop: 8 }}
                  onClick={() => setIsResultOpen(false)}
                  label="テストに戻る"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
