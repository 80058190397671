import { useEffect, useRef } from "react";
import { SearchIcon } from "../assets/icons/Icons";

const inputstyle = {
  minWidth: 200,
  height: 50,
  borderColor: "transparent",
  borderRadius: "10px 0px 0px 10px",
  backgroundColor: "#FCFCFF",
  marginTop: 2,
};

const buttonstyle = {
  height: 50,
  marginTop: 2,
  backgroundColor: "#FCFCFF",
  borderColor: "transparent",
  borderRadius: "0px 10px 10px 0px",
};
export const Search = ({
  value,
  style,
  onChange,
  onSearch = () => {},
  placeholder = "検索",
  autoFocus = false,
}) => {
  const el = useRef(null);

  useEffect(() => {
    if (autoFocus && el.current) {
      el.current.focus();
    }
  }, [autoFocus, el]);

  return (
    <div style={{ display: "flex" }}>
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{ ...inputstyle, ...style }}
        autoFocus={autoFocus}
        ref={el}
      />
      <button onClick={() => onSearch()} style={buttonstyle}>
        <SearchIcon />
      </button>
    </div>
  );
};
