import firebase from "../firebase_exports";
import { useState, useEffect, useContext, useCallback } from "react";
import { MiscContext } from "../contexts/Contexts";
import { Title } from "../components/Title";
import { HeaderHome } from "../components/HeaderBar";
import { PreviewItem } from "../components/PreviewItem";
import { WebBlock } from "../components/WebBlock";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../components/Button";
import { format, differenceInHours } from "date-fns";
import { web } from "../constants/colors";
import HeaderBg from "../assets/images/header_bg_new.png";
import LiveImg from "../assets/images/live_lecture_thumbnail.png";
import VoiceMediaImg from "../assets/images/voice_media_thumbnail.png";
import LectureImg from "../assets/images/lecture_thumbnail.png";

const buttonstyle = {
  backgroundColor: web.primary,
  color: "#C4C4C4",
  borderRadius: 5,
  width: 108,
  height: 25,
  fontSize: 12,
};

const baseTabStyle = {
  color: "white",
  fontWeight: 700,
  borderLeft: "0.5px solid white",
  borderRight: "0.5px solid white",
  paddingTop: 13,
  paddingBottom: 8,
  width: 130,
  marginBottom: -1,
};

export const HomePage = () => {
  const {
    announcementsList,
    newsList,
    getEvent,
    getLiveStream,
    listEventsOfCloser,
    listLiveStreamEventsOfCloser,
    highlightedEvents,
    highlightedLiveStreams,
    voiceMediumList,
  } = useContext(MiscContext);
  const { isMobile } = useContext(MiscContext);
  const [eventsOfToday, setEventsOfToday] = useState([]);
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [showNewsList, setShowNewsList] = useState([]);
  const [showAnnouncementList, setShowAnnouncementList] = useState([]);
  const [tabContent, setTabContent] = useState("announcements");

  const isItemNew = (created_at) => {
    let created_date = new firebase.firestore.Timestamp(created_at.seconds, created_at.nanoseconds)
      .toDate()
      .getTime();
    let now = new Date().getTime();
    return differenceInHours(now, created_date) < 24;
  };
  const setupHighlightedItems = useCallback(async () => {
    let tmp = [];
    let eventsPromise = new Promise((resolve) => {
      if (highlightedEvents.length > 0) {
        highlightedEvents.forEach(async (event, index, array) => {
          let item = await getEvent(event.id);
          tmp.push({ ...item, type: "events" });
          if (index === array.length - 1) resolve();
        });
      } else {
        resolve();
      }
    });
    let liveStreamPromise = new Promise((resolve) => {
      if (highlightedLiveStreams.length > 0) {
        highlightedLiveStreams.map(async (live_stream, index, array) => {
          let item = await getLiveStream(live_stream.id);
          tmp.push({ ...item, type: "live_streams" });
          if (index === array.length - 1) resolve();
        });
      } else {
        resolve();
      }
    });
    eventsPromise.then(() => {
      liveStreamPromise.then(() => {
        setHighlightedItems(tmp);
      });
    });
  }, [getEvent, getLiveStream, highlightedEvents, highlightedLiveStreams]);

  const history = useHistory();

  const setupCloserItems = useCallback(async () => {
    const now = new Date();
    let tmp = [];
    let items = await listEventsOfCloser(now, 10);
    tmp.push(
      ...items.map((item) => {
        return { ...item.data(), type: "events" };
      })
    );
    let live_streams = await listLiveStreamEventsOfCloser(now, 10);
    tmp.push(
      ...live_streams.map((live_stream) => {
        return { ...live_stream.data(), type: "live_streams" };
      })
    );
    tmp.sort((a, b) => a.datetime - b.datetime);
    setEventsOfToday(tmp);
    return;
  }, [listLiveStreamEventsOfCloser, listEventsOfCloser]);

  useEffect(() => {
    setupHighlightedItems();
    setupCloserItems();
  }, [setupCloserItems, setupHighlightedItems]);

  const compareFn = (a, b) => {
    let a_time = new firebase.firestore.Timestamp(
      a.created_at.seconds,
      a.created_at.nanoseconds
    ).toDate();
    let b_time = new firebase.firestore.Timestamp(
      b.created_at.seconds,
      b.created_at.nanoseconds
    ).toDate();

    return a_time - b_time;
  };

  useEffect(() => {
    setShowNewsList([...newsList].sort(compareFn));
    setShowAnnouncementList([...announcementsList].sort(compareFn));
  }, [newsList, announcementsList]);

  return (
    <>
      {isMobile ? (
        <div style={{ backgroundColor: "#FFFFFF" }}>
          <header>
            <HeaderHome />
          </header>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: "100%",
              height: "100%",
              minHeight: window.innerHeight,
              paddingTop: 40,
              paddingLeft: 24,
            }}
          >
            <div style={{ position: "absolute", right: 24, paddingTop: 16 }}>
              <Link to="/find_by_date">
                <Button style={buttonstyle} label="日付で探す >" />
              </Link>
            </div>
            <div style={{ marginTop: 42, paddingBottom: 10 }}>
              <Title
                style={{ fontSize: 16, fontWeight: 700, paddingBottom: 10 }}
                title="新着のイベント/LIVE配信"
              />
              <div style={{ display: "flex", overflowY: "hidden", overflowX: "scroll" }}>
                {!!eventsOfToday.length ? (
                  eventsOfToday.map((item) => (
                    <div style={{ marginRight: 10 }}>
                      <PreviewItem
                        link={`/${item.type}/${item.id}`}
                        title={item.title}
                        type={item.type}
                        datetime={item.datetime}
                        description={item.description}
                        imgsrc={item.thumbnail}
                      />
                    </div>
                  ))
                ) : (
                  <div style={{ marginLeft: 10, height: 268, marginTop: 8 }}>
                    予定されているイベント・LIVE配信はありません
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginTop: 31, paddingBottom: 10 }}>
              <Title title="注目のイベント・LIVE配信" style={{ paddingBottom: 10 }} />
              <div style={{ display: "flex", overflowY: "hidden", overflowX: "scroll" }}>
                {highlightedItems.length &&
                  highlightedItems.map((item) => (
                    <div style={{ marginRight: 10 }}>
                      <PreviewItem
                        link={`/${item.type}/${item.id}`}
                        title={item.title}
                        type={item.type}
                        datetime={item.datetime}
                        description={item.description}
                        imgsrc={item.thumbnail}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div style={{ marginTop: 31 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Title title="音声配信" />
                <div
                  style={{ paddingRight: 10, cursor: "pointer", color: web.primary }}
                  onClick={() => history.push("/voice_medium")}
                >
                  {"more >"}
                </div>
              </div>
              <div style={{ display: "flex", overflowY: "hidden", overflowX: "scroll" }}>
                {voiceMediumList.length &&
                  voiceMediumList.map((item) => (
                    <div style={{ marginRight: 10 }}>
                      <PreviewItem
                        link={`/voice_medium/${item.id}`}
                        title={item.title}
                        type={"voice_media"}
                        description={item.description}
                        imgsrc={item.thumbnail}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div style={{ marginTop: 108 }}>
            <img
              alt="header_fg"
              src={HeaderBg}
              style={{
                opacity: 1,
                width: "100%",
                left: 0,
                top: 108,
                zIndex: 1,
                objectFit: "cover",
              }}
            />
          </div>
          <WebBlock
            style={{
              height: 255,
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: 43,
                backgroundColor: "#5D8EA4",
                width: "100%",
                borderBottom: "1px solid white",
              }}
            >
              <div
                onClick={() => setTabContent("announcements")}
                style={{
                  ...baseTabStyle,
                  borderBottom: tabContent === "announcements" ? "1px solid red" : "",
                  cursor: "pointer",
                  marginBottom: -1,
                }}
              >
                新着情報
              </div>
              <div
                onClick={() => setTabContent("news")}
                style={{
                  ...baseTabStyle,
                  borderBottom: tabContent === "news" ? "1px solid red" : "",
                  cursor: "pointer",
                }}
              >
                ニュース
              </div>
            </div>
            <div>
              <div>
                <div style={{ marginTop: 5 }}>
                  {(tabContent === "announcements" ? showAnnouncementList : showNewsList)
                    .slice(-5)
                    .reverse()
                    .map((item) => {
                      return (
                        <div
                          style={{
                            color: "white",
                            width: 555,
                            textAlign: "left",
                            borderBottom: "1px solid white",
                            height: 25,
                            marginTop: 9,
                          }}
                        >
                          {format(
                            new firebase.firestore.Timestamp(
                              item.created_at.seconds,
                              item.created_at.nanoseconds
                            ).toDate(),
                            "yy.MM.dd"
                          )}
                          &nbsp; &nbsp;
                          {isItemNew(item.created_at) ? (
                            <>
                              <span
                                style={{
                                  borderRadius: 6,
                                  backgroundColor: "#CC0000",
                                  paddingTop: 1,
                                  paddingBottom: 1,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  fontWeight: 700,
                                  fontSize: 10,
                                  verticalAlign: "20%",
                                }}
                              >
                                NEW
                              </span>
                              &nbsp; &nbsp;
                            </>
                          ) : (
                            <span>&nbsp;&nbsp;</span>
                          )}
                          <Link
                            to={`/${tabContent}/${item.id}`}
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            {item.title.length > 26 ? `${item.title.slice(0, 26)}...` : item.title}
                          </Link>
                        </div>
                      );
                    })}
                  <div style={{ textAlign: "center", marginTop: 8 }}>
                    <Link
                      to={`/${tabContent}`}
                      style={{
                        color: web.primary,
                        backgroundColor: "white",
                        textDecoration: "none",
                        fontSize: 12,
                        borderRadius: 8,
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 14,
                        paddingRight: 14,
                        fontWeight: 700,
                      }}
                    >
                      もっと見る
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </WebBlock>
          <WebBlock secondary>
            <div style={{ marginTop: 88, fontSize: 36, fontWeight: 700, marginBottom: 114 }}>
              新着のイベント・LIVE配信
            </div>
            <div
              style={{
                display: "flex",
                overflowY: "hidden",
                overflowX: "scroll",
                justifyContent: "start",
              }}
            >
              {!!eventsOfToday.length ? (
                eventsOfToday.map((item) => (
                  <PreviewItem
                    link={`/${item.type}/${item.id}`}
                    title={item.title}
                    type={item.type}
                    datetime={item.datetime}
                    description={item.description}
                    imgsrc={item.thumbnail}
                  />
                ))
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ marginLeft: 10, height: 268, marginTop: 8 }}>
                    新着のイベント・LIVE配信はありません
                  </div>
                </div>
              )}
            </div>
          </WebBlock>
          <WebBlock>
            <div
              style={{
                paddingTop: 88,
                fontSize: 36,
                fontWeight: 700,
                marginBottom: 60,
                color: web.secondary,
              }}
            >
              CONTENTS
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  height: 540,
                  width: 290,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/find_by_date")}
              >
                <img style={{ width: 290, height: 290 }} alt="test" src={LiveImg} />
                <div style={{ fontWeight: 700, fontSize: 16, marginTop: 63 }}>
                  LIVE配信・イベント
                </div>
                <div>
                  毎月4回（１週間に１度）オンラインでのLIVE講義研修を行います。
                  メインは南原学長で、スペシャルゲストも招いて講義も行います。
                </div>
              </div>
              <div
                style={{
                  height: 540,
                  width: 290,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/categories")}
              >
                <img style={{ width: 290, height: 290 }} alt="test" src={LectureImg} />
                <div style={{ fontWeight: 700, fontSize: 16, marginTop: 63 }}>動画講義</div>
                <div>
                  様々な専門分野のプロフェッショナルが講義を担当します。
                  スマホひとつで空き時間でもどこでも学び放題！
                </div>
              </div>
              <div
                style={{
                  height: 540,
                  width: 290,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/voice_medium")}
              >
                <img style={{ width: 290, height: 290 }} alt="test" src={VoiceMediaImg} />
                <div style={{ fontWeight: 700, fontSize: 16, marginTop: 63 }}>音声配信</div>
                <div>
                  オンラインビジネススクール【ENCOLN】の学長を務める南原学長が
                  毎週月曜日〜金曜日のAM8:00(目安)から音声配信を行います。
                  ビジネスに纏わるトークはもちろんのこと、人生観やマネジメント論、南原学長のこれまでの人生経験に基づいた知見等を基にしたニュース解説等をお話します。
                </div>
              </div>
            </div>
          </WebBlock>
          <WebBlock secondary>
            <div
              style={{
                marginTop: 88,
                fontSize: 36,
                fontWeight: 700,
                marginBottom: 114,
                color: web.primary,
              }}
            >
              注目のイベント・LIVE配信
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                overflowY: "hidden",
                overflowX: "scroll",
                paddingLeft: 139,
              }}
            >
              {highlightedItems.length > 0 ? (
                highlightedItems.map((item) => (
                  <PreviewItem
                    link={`/${item.type}/${item.id}`}
                    title={item.title}
                    type={item.type}
                    datetime={item.datetime}
                    description={item.description}
                    imgsrc={item.thumbnail}
                  />
                ))
              ) : (
                <div style={{ marginLeft: 10, height: 268, marginTop: 8 }}>
                  現在注目のベント・LIVE配信はありません
                </div>
              )}
            </div>
          </WebBlock>
        </>
      )}
    </>
  );
};
