import { Link } from "react-router-dom";
import { web } from "../constants/colors";
export const NewsItem = ({ title, date, link, imgsrc, secondary = false, objectFit = "cover" }) => {
  return (
    <>
      <div
        style={{
          marginLeft: 49,
          marginRight: 49,
          marginBottom: 49,
          textAlign: "left",
          backgroundColor: "#FCFCFF",
          boxShadow: "0 4px 4px 0 gray",
        }}
      >
        <Link
          to={link}
          style={{
            textDecoration: "none",
            color: secondary ? web.secondary : web.primary,
          }}
        >
          <img
            src={imgsrc}
            style={{ width: 495, height: 278, objectFit: objectFit }}
            alt={imgsrc}
          ></img>
          <div style={{ height: 70, maxWidth: 495, marginLeft: 10 }}>
            <div style={{ fontWeight: 700, fontSize: 24 }}>{title}</div>
          </div>
          <div
            style={{ height: 25, display: "flex", marginLeft: 10, justifyContent: "space-between" }}
          >
            <p>{date}</p>
          </div>
        </Link>
      </div>
    </>
  );
};
