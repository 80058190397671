import { createSvgIcon } from "@material-ui/core/utils";

export default createSvgIcon(
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9374 1.1096C11.2684 0.852139 11.7319 0.852139 12.0629 1.1096L20.3129 7.52626C20.5362 7.69993 20.6668 7.96696 20.6668 8.24984V18.3332C20.6668 19.0625 20.3771 19.762 19.8614 20.2777C19.3457 20.7934 18.6462 21.0832 17.9168 21.0832H5.0835C4.35415 21.0832 3.65468 20.7934 3.13895 20.2777C2.62323 19.762 2.3335 19.0625 2.3335 18.3332V8.24984C2.3335 7.96696 2.4641 7.69993 2.68738 7.52626L10.9374 1.1096ZM4.16683 8.69816V18.3332C4.16683 18.5763 4.26341 18.8094 4.43532 18.9813C4.60722 19.1533 4.84038 19.2498 5.0835 19.2498H17.9168C18.1599 19.2498 18.3931 19.1533 18.565 18.9813C18.7369 18.8094 18.8335 18.5763 18.8335 18.3332V8.69816L11.5002 2.99446L4.16683 8.69816Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0415 11.8463C15.4103 10.5725 18.1768 10.6237 19.9886 11.0263L19.5114 13.1739C18.0232 12.8432 15.8397 12.8395 14.0835 13.7839C12.4318 14.6722 10.95 16.5095 10.95 20.3501H8.75C8.75 15.8307 10.5682 13.1764 13.0415 11.8463Z"
      fill="white"
    />
  </>
);
