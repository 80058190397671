import firebase from "../firebase_exports";
import React, { useState, useEffect } from "react";
import { differenceInHours } from "date-fns";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import { AuthContext, MiscContext } from "../contexts/Contexts";
import { ChevronLeftIcon, MailIcon, BellIcon, SettingsIcon } from "../assets/icons/Icons";
import { web } from "../constants/colors";

const headerbar = {
  backgroundColor: web.primary,
  width: "100%",
  height: "40px",
  display: "flex",
  position: "fixed",
  justifyContent: "flex-end",
  zIndex: 100,
};

const headerbar2 = {
  backgroundColor: web.primary,
  width: "100%",
  height: "50px",
  position: "fixed",
  display: "flex",
  justifyContent: "space-between",
  zIndex: 100,
  marginTop: "center",
};

const backIcon = {
  width: "40px",
  height: "40px",
  color: "#ffffff",
};

const arrow = {
  color: "#ffffff",
  textDecoration: "none",
  marginLeft: "24px",
  marginTop: "10px",
};

const titleword = {
  fontSize: "18px",
  textAlign: "center",
  color: "#ffffff",
  marginTop: "10px",
};

export const HeaderHome = () => {
  const { isMobile, announcementsList, newsList } = useContext(MiscContext);
  const [hasNewAnnoucement, setHasNewAnnoucement] = useState(false);
  const [hasNewNews, setHasNewNews] = useState(false);
  useEffect(() => {
    let now = new Date();
    let latest_annoucements_created = new firebase.firestore.Timestamp(
      announcementsList[0].created_at.seconds,
      announcementsList[0].created_at.nanoseconds
    ).toDate();
    let latest_news_created = new firebase.firestore.Timestamp(
      newsList[0].created_at.seconds,
      newsList[0].created_at.nanoseconds
    ).toDate();
    setHasNewAnnoucement(differenceInHours(now, latest_annoucements_created) < 24 * 1);
    setHasNewNews(differenceInHours(now, latest_news_created) < 24 * 1);
  }, [announcementsList, newsList]);
  return (
    <>
      {isMobile && (
        <div style={headerbar}>
          <div style={{ marginTop: 9 }}>
            <Link to="/news" style={{ position: "relative" }}>
              {hasNewNews && (
                <div
                  style={{
                    backgroundColor: "#FF6060",
                    border: "1px solid white",
                    position: "absolute",
                    borderRadius: "50%",
                    top: -6,
                    left: 20,
                    width: 10,
                    height: 10,
                  }}
                />
              )}
              <MailIcon style={{ marginRight: 27 }} />
            </Link>
            <Link to="/announcements" style={{ position: "relative" }}>
              {hasNewAnnoucement && (
                <div
                  style={{
                    backgroundColor: "#FF6060",
                    border: "1px solid white",
                    position: "absolute",
                    borderRadius: "50%",
                    width: 10,
                    height: 10,
                    top: -6,
                    left: 20,
                  }}
                />
              )}
              <BellIcon style={{ marginRight: 26 }} />
            </Link>
            <Link to="/settings">
              <SettingsIcon style={{ marginRight: 10 }} />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export const HeaderMenu = ({ title, onClick }) => {
  const history = useHistory();
  const { isMobile } = useContext(MiscContext);
  return (
    <>
      {isMobile && (
        <div style={headerbar2}>
          <div style={arrow} onClick={() => (onClick ? onClick() : history.goBack())}>
            <ChevronLeftIcon style={backIcon} />
          </div>
          <div style={titleword}>{title}</div>
          <div />
        </div>
      )}
    </>
  );
};

const Logo = () => (
  <img style={{ width: 180 }} src={`${process.env.PUBLIC_URL}/logo200.png`} alt="encoln-logo" />
);
const MenubarItem = ({
  label,
  hoverState,
  setHoverState = () => {},
  id,
  disabled = false,
  options = [],
  onClick = () => {},
}) => {
  const menubarItemStyle = {
    fontWeight: 700,
    color: disabled ? web.primary + "55" : web.primary,
    fontSize: 18,
    margin: "0px 8px",
    padding: "0px 8px",
    textDecoration: "none",
    cursor: "pointer",
  };

  return (
    <>
      <div
        onMouseOver={() => setHoverState(id)}
        style={menubarItemStyle}
        onClick={disabled ? () => {} : onClick}
      >
        {label}
        {hoverState === id && options.length > 0 && (
          <ul
            onMouseLeave={() => setHoverState(0)}
            style={{
              cursor: "pointer",
              position: "absolute",
              backgroundColor: web.primary,
              color: web.secondary,
              marginTop: 10,
              marginLeft: -50,
              padding: 7,
              width: 150,
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {options.map((item) => (
              <li style={{ padding: 4 }}>
                {item.external ? (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: item.disabled ? web.secondary + "33" : web.secondary,
                    }}
                    href={item.link}
                  >
                    {item.label}
                  </a>
                ) : item.link ? (
                  <Link
                    style={{
                      textDecoration: "none",
                      color: item.disabled ? web.secondary + "55" : web.secondary,
                    }}
                    to={item.disabled ? "/" : item.link}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <div
                    style={{
                      textDecoration: "none",
                      color: item.disabled ? web.secondary + "55" : web.secondary,
                      cursor: "pointer",
                    }}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export const NavbarWeb = () => {
  const history = useHistory();
  const [hoverState, setHoverState] = useState(0);
  const { isMobile } = useContext(MiscContext);
  const { user, isValidPaidAccount, logout } = useContext(AuthContext);
  return (
    <>
      {!isMobile && (
        <div
          onMouseLeave={() => setHoverState(0)}
          style={{
            position: "fixed",
            top: 0,
            paddingLeft: 139,
            paddingRight: 139,
            left: 0,
            right: 0,
            height: 108,
            backgroundColor: web.secondary,
            zIndex: 100,
            boxShadow: "0 2px 4px 0 #999999",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                onClick={() => history.push("/")}
                style={{
                  height: 108,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Logo />
              </div>
            </div>
            <div
              style={{
                height: 108,
                display: "flex",
                flexDirection: "row",
                color: web.primary,
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <MenubarItem label="ホーム" onClick={() => history.push("/")} />
              <MenubarItem
                id={1}
                hoverState={hoverState}
                setHoverState={setHoverState}
                disabled={!user}
                options={[
                  {
                    label: "LIVE講義/イベント",
                    link: "/find_by_date",
                    disabled: !isValidPaidAccount,
                  },
                  { label: "音声配信", link: "/voice_medium", disabled: !isValidPaidAccount },
                  { label: "動画講義", link: "/categories" },
                ]}
                label="学ぶ"
                onClick={() => setHoverState(hoverState ? 0 : 1)}
              />
              <MenubarItem
                disabled={!isValidPaidAccount}
                onClick={() => history.push("/find_user")}
                label="繋がる"
              />
              <MenubarItem
                id={2}
                hoverState={hoverState}
                setHoverState={setHoverState}
                disabled={!user}
                options={[
                  { label: "プロフィール", link: "/mypage" },
                  {
                    label: "個人情報保護法",
                    external: true,
                    link: "https://www.encoln.com/%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E4%BF%9D%E8%AD%B7%E6%B3%95",
                  },
                  {
                    label: "特商法",
                    external: true,
                    link: "https://www.encoln.com/%E8%A4%87%E8%A3%BD-%E9%81%8B%E5%96%B6%E4%BC%9A%E7%A4%BE%E6%83%85%E5%A0%B1",
                  },
                  {
                    label: "利用規約",
                    external: true,
                    link: "https://www.encoln.com/%E8%A4%87%E8%A3%BD-%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E3%81%AE%E5%8F%96%E3%82%8A%E6%89%B1%E3%81%84",
                  },
                  { label: "運営会社", external: true, link: "https://www.luft-hd.co.jp/" },
                  {
                    label: "退学検討者はこちら",
                    external: true,
                    link: "https://www.encoln.com/post/lets-still-learn",
                  },
                  { label: "ログアウト", onClick: () => logout() },
                ]}
                label="マイページ"
                onClick={() => setHoverState(hoverState ? 0 : 2)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
