import { HeaderMenu } from "../../components/HeaderBar";

export const TermsAndConditionsPage = () => {
  return (
    <>
      <header>
        <HeaderMenu title="設定" />
      </header>
      <div style={{margin:0, padding:0, width:'100vw', height:'100vh', backgroundColor:'#373E4D'}}>
        <div style={{marginLeft:24, padding:8, marginRight:24}}>
          <p style={{color:'#C4C4C4'}}>encoln利用規約</p>
          <p style={{color:'#C4C4C4', marginTop:16}}>
            あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。
        　  またそのなかでいっしょになったたくさんのひとたち、ファゼーロとロザーロ、羊飼のミーロや、顔の赤いこどもたち、地主のテーモ、山猫博士のボーガント・デストゥパーゴなど、いまこの暗い巨きな石の建物のなかで考えていると、みんなむかし風のなつかしい青い幻燈のように思われます。では、わたくしはいつかの小さなみだしをつけながら、しずかにあの年のイーハトーヴォの五月から十月までを書きつけましょう。
          </p>
        </div>
      </div>
    </>
  );
};
