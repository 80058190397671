import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { User } from "../components/User";
import { Search } from "../components/Search";
import { Button } from "../components/Button";
import { DropDownList } from "../components/DropDownList";
import { AuthContext, UsersContext, MiscContext } from "../contexts/Contexts";
import { prefectureChoices, industryChoices, attributeChoices } from "../constants/user_choices";

export const FindUserPage = () => {
  const [searchText, setSearchText] = useState("");
  const [industry, setIndustry] = useState({ label: "業界を選択", value: "" });
  const [attribute, setAttribute] = useState({ label: "属性を選択", value: "" });
  const [area, setArea] = useState({ label: "地域を選択", value: "" });
  const [searched, setSearched] = useState(false);
  const { usersList, queryUserByMulti, loadMoreUsers } = useContext(UsersContext);
  const { loggedIn, isValidPaidAccount, setShowUpgradeModal } = useContext(AuthContext);
  const { isMobile } = useContext(MiscContext);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (loggedIn && !isValidPaidAccount) {
      setShowUpgradeModal(true);
      history.push("/");
    }

    setFilteredUsers(usersList);
  }, [loggedIn, usersList, isValidPaidAccount, setShowUpgradeModal, history]);

  const industryOptions = Object.entries(industryChoices).map(([key, value]) => ({
    label: key,
    options: value.map((item) => ({ label: item, value: item })),
  }));
  const attributeOptions = attributeChoices.map((item) => ({ label: item, value: item }));
  const areaOptions = prefectureChoices.map((e) => ({ label: e, value: e }));

  const handleSearch = async () => {
    setSearched(true);
    let filters = [
      { label: "attribute", value: attribute.value, cond: "==" },
      { label: "industry", value: industry.value, cond: "==" },
      { label: "area", value: area.value, cond: "==" },
      { label: "name", value: searchText, cond: "==" },
    ];

    // Name search can be done here instead?

    let result = await queryUserByMulti(filters);
    setFilteredUsers(result);
  };

  const clearFilters = () => {
    setSearchText("");
    setAttribute({ label: "属性を選択", value: "" });
    setIndustry({ label: "業界を選択", value: "" });
    setArea({ label: "地域を選択", value: "" });
    setFilteredUsers(usersList);
    setSearched(false);
  };

  return (
    <>
      {loggedIn ? (
        <div
          style={
            isMobile
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 30,
                  marginLeft: 24,
                  marginRight: 24,
                }
              : {
                  marginTop: 100,
                  marginLeft: "10%",
                  marginRight: "10%",
                  width: "80%",
                  paddingTop: 30,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }
          }
        >
          <Search
            value={searchText}
            onChange={setSearchText}
            onSearch={handleSearch}
            placeholder="名前またはキーワード"
          />
          <div style={{ height: 20 }} />
          <div style={{ display: "flex" }}>
            <DropDownList
              width={isMobile ? 90 : 200}
              options={[{ label: "業界を選択", value: "" }, ...industryOptions]}
              value={industry}
              onChange={(item) => setIndustry(item)}
              placeholder="業界"
            />
            <DropDownList
              width={isMobile ? 90 : 200}
              options={[{ label: "属性を選択", value: "" }, ...attributeOptions]}
              value={attribute}
              onChange={(item) => setAttribute(item)}
              placeholder="属性"
            />
            <DropDownList
              width={isMobile ? 90 : 200}
              options={[{ label: "地域を選択", value: "" }, ...areaOptions]}
              value={area}
              onChange={(item) => setArea(item)}
              placeholder="地域"
            />
          </div>
          <div style={{ height: 20 }} />
          <div>
            <Button
              onClick={async () => await handleSearch()}
              style={{ minWidth: 100, height: 40, marginRight: 10 }}
              label="この条件で検索"
            />
            <Button
              onClick={() => clearFilters()}
              style={{ minWidth: 100, height: 40 }}
              label="条件クリア"
            />
          </div>
          <div
            style={
              isMobile
                ? { marginTop: 40 }
                : {
                    marginTop: 40,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }
            }
          >
            {filteredUsers.map((user) => {
              return (
                <div
                  key={user.id}
                  style={
                    isMobile
                      ? { marginTop: 8, marginBottom: 8 }
                      : { marginTop: 8, marginBottom: 8, width: 400 }
                  }
                >
                  <User
                    isPreview={true}
                    isMobile={true}
                    avatar={user.avatar}
                    name={`${user.family_name} ${user.given_name}`}
                    industry={user.industry}
                    attribute={user.attribute}
                    onClick={() => history.push(`/users/${user.id}`)}
                    key={user.id}
                  />
                </div>
              );
            })}
            {filteredUsers.length !== 0 ? null : <>ユーザーが見つかりませんでした</>}
          </div>
          {!searched && (
            <Button
              style={{ width: 200, height: 40, marginTop: 20, marginBottom: 20 }}
              onClick={async () => await loadMoreUsers()}
              label="さらに読み込む"
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
