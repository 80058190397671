import { auth, firestore } from "../firebase_exports";
import { useState, useEffect, createContext, useCallback, useMemo } from "react";

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const profileInitialState = useMemo(() => {
    return {
      email: "",
      family_name: "",
      family_name_kana: "",
      given_name: "",
      given_name_kana: "",
      id: "",
      progress_ids: [],
      valid_until: 0,
      subscribed: false,
      publics: ["area", "gender", "birthdate", "introduction"],
    };
  }, []);
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(profileInitialState);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const onAuthStateChanged = useCallback(
    (user) => {
      // console.log("Changed Auth State");
      // console.log(user);
      setUser(user);
      if (user) {
        firestore
          .collection("profiles")
          .doc(user.uid)
          .onSnapshot((doc) => {
            setProfile(doc.data());
            setLoggedIn(true);
            setEmailVerified(user.emailVerified);
            setInitialized(true);
          });
      } else {
        setProfile(profileInitialState);
        setLoggedIn(false);
        setEmailVerified(false);
        setInitialized(true);
      }
    },
    [profileInitialState]
  );
  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, [onAuthStateChanged]);

  const login = ({ email, password }) => {
    // This returns promise
    return auth.signInWithEmailAndPassword(email, password);
  };
  const logout = () => {
    return auth.signOut();
  };

  const isValidPaidAccount = useMemo(() => {
    let now = new Date();
    let valid_until = new Date(profile.valid_until);
    return valid_until > now;
  }, [profile]);

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        login,
        logout,
        showUpgradeModal,
        setShowUpgradeModal,
        isValidPaidAccount,
        loggedIn,
        initialized,
        emailVerified,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
