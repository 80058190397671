import { createSvgIcon } from "@material-ui/core/utils";

export default createSvgIcon(
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.1665 5.20833C3.59597 5.20833 3.12484 5.67946 3.12484 6.25V18.75C3.12484 19.3205 3.59597 19.7917 4.1665 19.7917H20.8332C21.4037 19.7917 21.8748 19.3205 21.8748 18.75V6.25C21.8748 5.67946 21.4037 5.20833 20.8332 5.20833H4.1665ZM1.0415 6.25C1.0415 4.52887 2.44537 3.125 4.1665 3.125H20.8332C22.5543 3.125 23.9582 4.52887 23.9582 6.25V18.75C23.9582 20.4711 22.5543 21.875 20.8332 21.875H4.1665C2.44537 21.875 1.0415 20.4711 1.0415 18.75V6.25Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.22989 5.65265C1.5598 5.18135 2.20932 5.06673 2.68062 5.39664L12.4999 12.2702L22.3192 5.39664C22.7905 5.06673 23.4401 5.18135 23.77 5.65265C24.0999 6.12395 23.9853 6.77346 23.514 7.10337L13.0973 14.395C12.7386 14.6461 12.2612 14.6461 11.9026 14.395L1.4859 7.10337C1.0146 6.77346 0.899983 6.12395 1.22989 5.65265Z"
      fill="white"
    />
  </>
);
