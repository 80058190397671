import { useHistory } from "react-router-dom";
import { web, mobile } from "../constants/colors";
export const Hashtag = ({ value, link_to, isMobile }) => {
  const f12m0 = { fontSize: 12, margin: 0 };
  const history = useHistory();
  return (
    <div
      style={{
        minHeight: isMobile ? 25 : 32,
        backgroundColor: isMobile ? mobile.primary : web.primary,
        borderRadius: 3,
      }}
      onClick={(e) => history.push(link_to)}
    >
      <div
        style={{
          ...f12m0,
          marginLeft: 9,
          marginRight: 11,
          fontSize: isMobile ? 12 : 16,
          paddingTop: 3,
          fontWeight: 700,
          color: isMobile ? "black" : web.secondary,
        }}
      >
        #{value}
      </div>
    </div>
  );
};
