import React from "react";
import { useContext } from "react";
import { MiscContext } from "../contexts/Contexts";
import { HomeIcon, FriendIcon, UserIcon, LectureIcon } from "../assets/icons/Icons";
import { Link, useLocation } from "react-router-dom";
import { web, mobile } from "../constants/colors";
import { SnsIconLink } from "../components/SnsIconLink";

const footerbar = {
  backgroundColor: web.primary,
  width: "100%",
  height: "70px",
  position: "fixed",
  bottom: "0px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
};

const iconStyle = {
  width: "22px",
  height: "22px",
  color: "white",
};

const iconSelected = {
  color: mobile.primary,
  width: "22px",
  height: "22px",
  fill: mobile.primary,
  stroke: mobile.primary,
  strokeWidth: 2,
};

export const FooterBar = () => {
  const { isMobile } = useContext(MiscContext);
  const { pathname } = useLocation();
  return (
    <>
      {isMobile && pathname !== "/login" && (
        <div style={footerbar}>
          <Link to="/">
            <HomeIcon style={"/" === pathname ? iconSelected : iconStyle} />
          </Link>
          <Link to="/categories">
            <LectureIcon style={"/categories" === pathname ? iconSelected : iconStyle} />
          </Link>
          <Link to="/find_user">
            <FriendIcon style={"/find_user" === pathname ? iconSelected : iconStyle} />
          </Link>
          <Link to="/mypage">
            <UserIcon style={"/mypage" === pathname ? iconSelected : iconStyle} />
          </Link>
        </div>
      )}
      {!isMobile && (
        <div style={{ height: 466 }}>
          <div
            style={{
              height: 386,
              backgroundColor: web.primary,
              paddingTop: 77,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  width: 140,
                  color: web.secondary,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ fontSize: 17, fontWeight: 700 }}>規約などについて</div>
                <div style={{ marginTop: 14, fontSize: 14 }}>
                  <div style={{ marginBottom: 27 }}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white", fontSize: 14 }}
                      href="https://www.encoln.com/%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E4%BF%9D%E8%AD%B7%E6%B3%95"
                    >
                      個人情報取り扱い規約
                    </a>
                  </div>
                  <div style={{ marginBottom: 27 }}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white", fontSize: 14 }}
                      href="https://www.encoln.com/%E8%A4%87%E8%A3%BD-%E9%81%8B%E5%96%B6%E4%BC%9A%E7%A4%BE%E6%83%85%E5%A0%B1"
                    >
                      特商法
                    </a>
                  </div>
                  <div style={{ marginBottom: 27 }}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white", fontSize: 14 }}
                      href="https://www.encoln.com/%E8%A4%87%E8%A3%BD-%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E3%81%AE%E5%8F%96%E3%82%8A%E6%89%B1%E3%81%84"
                    >
                      利用規約
                    </a>
                  </div>
                  <div style={{ marginBottom: 27 }}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white", fontSize: 14 }}
                      href="https://www.luft-hd.co.jp/"
                    >
                      運営会社
                    </a>
                  </div>
                </div>
              </div>
              <div style={{ width: 356, color: web.secondary }}>
                <div style={{ fontSize: 17, fontWeight: 700 }}>お問い合わせ</div>
                <div style={{ color: web.secondary, lineHeight: 2, fontSize: 14 }}>
                  <div>メール: encoln.school@gmail.com</div>
                  <div>電話: 03-6273-3350 (11:00-18:00) 週末祝日除</div>
                  <div>FAX: 03-5510-7726</div>
                  <div>住所: 東京都港区虎ノ門1-8-11 5825第1ビル5階</div>
                  <div>ENCOLN【社会人のオンラインスクール】</div>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SnsIconLink sns="instagram" username="encoln_school/?hl=ja" />
              <SnsIconLink sns="facebook" username={`groups/encolncom`} />
              <SnsIconLink sns="twitter" username="nambaratatsuki" />
              <SnsIconLink sns="line" username="@nambara?from=page" />
              <SnsIconLink sns="tiktok" username="tatsukinambara?lang=ja-JP" />
              <SnsIconLink
                sns="youtube"
                username="https://youtube.com/channel/UCPAm9wx6kWdUeUO9PsYmE2Q"
              />
            </div>

            <div
              style={{
                height: 80,
                backgroundColor: web.secondary,
                textAlign: "center",
                paddingTop: 27,
              }}
            >
              © All rights reserved. 株式会社LUFTホールディングス 2021
            </div>
          </div>
        </div>
      )}
    </>
  );
};
