import profileIcon from "../assets/images/blank_profile.png";
export const User = ({ name, industry, attribute, avatar, onClick, isMobile, isPreview }) => {
  const cardStyle = {
    height: isMobile ? 111 : 165,
    width: isMobile ? 327 : 654,
    minWidth: isMobile ? 200 : 654,
    backgroundColor: "white",
    borderRadius: "10px",
  };
  const avatarStyle = {
    width: isMobile ? 79 : 128,
    height: isMobile ? 79 : 128,
    objectFit: "cover",
    position: "relative",
    top: 16,
    bottom: "16",
    marginRight: 16,
    borderRadius: 10,
    backgroundColor: isMobile && "white",
  };
  const Row = ({ title, value, titleStyle, valueStyle }) => (
    <div style={{ marginTop: !isMobile && 6, display: "flex" }}>
      <span style={{ fontSize: isMobile ? 12 : 18, whiteSpace: "nowrap", ...titleStyle }}>
        {title}
      </span>
      <span
        style={{ fontSize: isMobile ? 12 : 18, fontWeight: 700, paddingLeft: 8, ...valueStyle }}
      >
        {isPreview ? (value.slice(0, 10) !== value ? value.slice(0, 10) + "..." : value) : value}
      </span>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <>
          <div
            style={{ ...cardStyle, display: "flex", justifyContent: "space-between" }}
            onClick={() => (onClick ? onClick() : null)}
          >
            <div style={{ paddingTop: 18, paddingLeft: 16 }}>
              <Row title="名前" value={name} valueStyle={{ fontSize: 18 }} />
              <Row title="業界" value={industry} valueStyle={{ overflow: "hidden" }} />
              <Row title="属性" value={attribute} />
            </div>
            <img
              src={avatar ? avatar.src : profileIcon}
              style={avatarStyle}
              alt={avatar ? avatar.title : ""}
            ></img>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ ...cardStyle, display: "flex", justifyContent: "space-between" }}
            onClick={() => (onClick ? onClick() : null)}
          >
            <div style={{ paddingTop: 24, paddingLeft: 16 }}>
              <Row title="名前" value={name} valueStyle={{ fontSize: 24 }} />
              <Row title="業界" value={industry} valueStyle={{ overflow: "hidden" }} />
              <Row title="属性" value={attribute} />
            </div>
            <img
              src={avatar ? avatar.src : profileIcon}
              style={avatarStyle}
              alt={avatar ? avatar.title : ""}
            ></img>
          </div>
        </>
      )}
    </>
  );
};
