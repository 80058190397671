import { createSvgIcon } from "@material-ui/core/utils";

export default createSvgIcon(
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.34426 3.17743C8.71171 1.80997 10.5664 1.04175 12.5002 1.04175C14.4341 1.04175 16.2888 1.80997 17.6562 3.17743C19.0237 4.54488 19.7919 6.39954 19.7919 8.33341C19.7919 11.8258 20.5389 13.9945 21.2272 15.2565C21.5728 15.8901 21.9094 16.3058 22.1432 16.5526C22.2603 16.6762 22.3524 16.7582 22.4078 16.8044C22.4356 16.8275 22.4542 16.8417 22.4622 16.8477C22.4634 16.8486 22.4644 16.8493 22.4651 16.8499C22.8386 17.1064 23.0038 17.5756 22.8722 18.0103C22.7392 18.4496 22.3343 18.7501 21.8752 18.7501H3.12524C2.66622 18.7501 2.26129 18.4496 2.12827 18.0103C1.99667 17.5756 2.16192 17.1064 2.53534 16.8499C2.53607 16.8493 2.53706 16.8486 2.53831 16.8477C2.54633 16.8417 2.56493 16.8275 2.59266 16.8044C2.6481 16.7582 2.74016 16.6762 2.85732 16.5526C3.0911 16.3058 3.42765 15.8901 3.77327 15.2565C4.46163 13.9945 5.20858 11.8258 5.20858 8.33341C5.20858 6.39954 5.9768 4.54488 7.34426 3.17743ZM2.54587 16.8427C2.54595 16.8427 2.54603 16.8426 2.54611 16.8426C2.54611 16.8426 2.5461 16.8426 2.5461 16.8426L2.54587 16.8427ZM5.36412 16.6667H19.6364C19.5571 16.5369 19.4775 16.3994 19.3983 16.2541C18.5241 14.6515 17.7086 12.1327 17.7086 8.33341C17.7086 6.95208 17.1598 5.62732 16.1831 4.65057C15.2063 3.67382 13.8816 3.12508 12.5002 3.12508C11.1189 3.12508 9.79415 3.67381 8.81739 4.65057C7.84064 5.62732 7.29191 6.95208 7.29191 8.33341C7.29191 12.1327 6.47636 14.6515 5.60222 16.2541C5.52295 16.3994 5.44338 16.5369 5.36412 16.6667Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1754 20.974C10.673 20.6854 11.3105 20.8548 11.5991 21.3524C11.6907 21.5103 11.8221 21.6413 11.9803 21.7324C12.1384 21.8235 12.3177 21.8714 12.5002 21.8714C12.6827 21.8714 12.8619 21.8235 13.0201 21.7324C13.1782 21.6413 13.3096 21.5103 13.4012 21.3524C13.6899 20.8548 14.3273 20.6854 14.8249 20.974C15.3226 21.2627 15.492 21.9001 15.2033 22.3978C14.9286 22.8713 14.5343 23.2644 14.0599 23.5377C13.5855 23.8109 13.0476 23.9547 12.5002 23.9547C11.9527 23.9547 11.4148 23.8109 10.9404 23.5377C10.466 23.2644 10.0718 22.8713 9.79705 22.3978C9.50838 21.9001 9.67778 21.2627 10.1754 20.974Z"
      fill="white"
    />
  </>
);
