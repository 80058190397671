import { useContext } from "react";
import { MiscContext } from "../contexts/Contexts";
import { web, mobile } from "../constants/colors";
import Select from "react-select";
const dropdownstyle = {
  container: (styles, { selectProps: { width = 150, isMobile = true, light = false } }) => ({
    ...styles,
    transition: "opacity 300ms",
    marginLeft: 10,
    borderRadius: 5,
    width: width,
    fontSize: isMobile ? 10 : 12,
    backgroundColor: web.secondary,
    color: web.primary,
    border: `1px solid ${isMobile ? mobile.secondary + "55" : web.primary}`,
  }),
  control: (styles, { selectProps: { isMobile = true, light = false } }) => ({
    ...styles,
    transition: "opacity 300ms",
    backgroundColor: web.secondary,
    color: web.primary,
    height: 25,
  }),
  option: (styles, { selectProps: { isMobile = true, light = false } }) => ({
    ...styles,
    transition: "opacity 300ms",
    backgroundColor: web.secondary,
    color: web.primary,
  }),
  input: (styles, { selectProps: { isMobile = true, light = false } }) => ({
    ...styles,
    transition: "opacity 300ms",
    backgroundColor: web.secondary,
    color: web.primary,
  }),
  placeholder: (styles, { selectProps: { isMobile = true, light = false } }) => ({
    ...styles,
    transition: "opacity 300ms",
    backgroundColor: web.secondary,
    color: web.primary,
  }),
  menuList: (styles, { selectProps: { isMobile = true, light = false } }) => ({
    ...styles,
    transition: "opacity 300ms",
    border: `1px solid ${isMobile ? (!light ? mobile.secondary : "#ffffff") : web.primary}`,
    backgroundColor: web.secondary,
    color: web.primary,
    borderRadius: 5,
  }),
  singleValue: (styles, { selectProps: { isMobile = true, light = false } }) => ({
    ...styles,
    transition: "opacity 300ms",
    backgroundColor: web.secondary,
    color: web.primary,
  }),
};

// Title for Events or news or notifications or what so ever
export const DropDownList = ({ ...args }) => {
  const { isMobile } = useContext(MiscContext);
  return <Select styles={dropdownstyle} {...args} isMobile={isMobile} />;
};
