export const PersonalData = ({ sex, birthday, hub, Selfintroduction, isMobile }) => {
  const textStyle = { fontSize: isMobile ? 12 : 18 };
  const rowStyle = { display: "flex", justifyContent: "space-between" };
  return (
    <>
      {isMobile ? (
        <>
          <div style={{ paddingLeft: 2, width: 168 }}>
            <div style={{ ...rowStyle }}>
              <div style={textStyle}>性別</div>
              <div style={{ ...textStyle, fontWeight: 700, margin: 0 }}>{sex}</div>
            </div>
            <div style={{ height: "12", ...rowStyle }}>
              <div style={textStyle}>生年月日</div>
              <div style={{ ...textStyle, fontWeight: 700 }}>{birthday}</div>
            </div>
            <div style={{ height: "12", ...rowStyle }}>
              <div style={textStyle}>拠点</div>
              <div style={{ ...textStyle, fontWeight: 700 }}>{hub}</div>
            </div>
            <div>
              <div style={textStyle}>自己紹介</div>
            </div>
          </div>
          <div
            style={{
              ...textStyle,
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              lineHeight: "1.5em",
            }}
          >
            {Selfintroduction}
          </div>
        </>
      ) : (
        <>
          <div style={{ width: 456 }}>
            <div style={{ ...rowStyle }}>
              <div style={textStyle}>性別</div>
              <div style={{ ...textStyle, fontWeight: 700, margin: 0 }}>{sex}</div>
            </div>
            <div style={{ height: "12", ...rowStyle }}>
              <div style={textStyle}>生年月日</div>
              <div style={{ ...textStyle, fontWeight: 700 }}>{birthday}</div>
            </div>
            <div style={{ height: "12", ...rowStyle }}>
              <div style={textStyle}>拠点</div>
              <div style={{ ...textStyle, fontWeight: 700 }}>{hub}</div>
            </div>
            <div>
              <div style={{ ...textStyle, marginBottom: 14 }}>自己紹介</div>
            </div>
          </div>
          <div
            style={{
              ...textStyle,
              wordBreak: "break-all",
              marginBottom: 16,
              whiteSpace: "pre-wrap",
              lineHeight: "1.5em",
            }}
          >
            {Selfintroduction}
          </div>
        </>
      )}
    </>
  );
};
