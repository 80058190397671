import { useContext } from "react";
import { MiscContext } from "../contexts/Contexts";

export const InputBox = ({ value, onChange, placeholder, style, type }) => {
  const { isMobile } = useContext(MiscContext);

  const inputStyle = {
    mobile: {
      background: "#FCFCFF",
      borderRadius: 10,
      height: 50,
      fontSize: 14,
      color: "#91919F",
      width: "327px",
    },
    web: {
      background: "#FFFFFF",
      borderRadius: 2,
      borderWidth: 2,
      borderColor: "#000000",
      color: "#000000",
      width: 303,
      height: 52,
    },
  };

  return (
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      style={isMobile ? { ...inputStyle.mobile, ...style } : { ...inputStyle.web, ...style }}
      type={type}
    />
  );
};
