export const userInitialState = {
  given_name: "",
  given_name_kana: "",
  family_name: "",
  family_name_kana: "",
  area: "",
  birthdate: "",
  contacts: {},
  gender: "",
  industry: "",
  interests: [],
  introduction: "",
  occupation: "",
  attribute: "",
  publics: [],
  sns_data: {
    instagram: "",
    facebook: "",
    twitter: "",
    line: "",
  },
};

export const genderChoices = ["男性", "女性", "その他"];

export const prefectureChoices = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "奈良県",
  "和歌山県",
  "京都府",
  "大阪府",
  "兵庫県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
  "海外",
];

export const attributeChoices = [
  "経営者",
  "役員",
  "正社員",
  "パート・アルバイト",
  "自営業",
  "学生",
  "無職",
  "派遣社員",
  "投資家",
  "その他",
];

export const occupationChoices = {
  営業: [
    "企画営業・法人営業・個人営業・MR・その他営業関連",
    "テレマーケティング・コールセンター",
    "キャリアカウンセラー・人材コーディネーター",
  ],
  "事務・管理": [
    "一般事務・アシスタント・受付・秘書・その他事務関連",
    "財務・会計・経理",
    "総務・人事・法務・知財・広報・IR",
    "物流・資材購買・貿易",
  ],
  "企画・マーケティング・経営・管理職": [
    "商品企画・営業企画・マーケティング・宣伝",
    "経営企画・事業統括・新規事業開発",
    "管理職・エグゼクティブ",
    "MD・バイヤー・店舗開発・FCオーナー",
  ],
  "サービス・販売・外食": [
    "小売・流通・外食・アミューズメント",
    "美容・エステ・リラクゼーション関連",
    "旅行・ホテル・航空・ブライダル・葬祭",
  ],
  "Web・インターネット・ゲーム": [
    "Webサイト・インターネットサービス",
    "ゲーム・マルチメディア関連",
  ],
  "クリエイティブ（メディア・アパレル・デザイン）": [
    "広告・グラフィック関連",
    "出版・印刷関連",
    "映像・音響・イベント・芸能・テレビ・放送関連",
    "ファッション・インテリア・空間・プロダクトデザイン",
  ],
  "専門職（コンサルタント・士業・金融・不動産）": [
    "ビジネスコンサルタント・シンクタンク",
    "士業・専門コンサルタント",
    "金融系専門職",
    "不動産・プロパティマネジメント系専門職",
  ],
  "ITエンジニア（システム開発・SE・インフラ）": [
    "システムコンサルタント・システムアナリスト・プリセールス",
    "システム開発（Web・オープン・モバイル系）",
    "システム開発（汎用機系）",
    "システム開発（組み込み、ファームウェア、制御系）",
    "パッケージソフト・ミドルウェア開発",
    "ネットワーク・サーバ設計・構築（LAN・WAN・Web系）",
    "通信インフラ設計・構築（キャリア・ISP系）",
    "運用・保守・監視・テクニカルサポート",
    "社内SE・情報システム",
    "研究・特許・テクニカルマーケティング",
    "品質管理",
  ],
  "エンジニア（機械・電気・電子・半導体・制御）": [
    "回路・システム設計",
    "半導体設計",
    "制御設計",
    "機械・機構設計・金型設計",
    "光学技術・光学設計",
    "生産技術・プロセス開発",
    "品質管理・製品評価・品質保証・生産管理・製造管理",
    "セールスエンジニア・フィールドアプリケーションエンジニア（FAE）",
    "サービスエンジニア・サポートエンジニア",
    "研究・特許・テクニカルマーケティング",
    "評価・検査・実験",
  ],
  "素材・化学・食品・医薬品技術": [
    "素材・化学・食品・医薬品技術職",
    "化粧品・食品・香料関連",
    "医薬品関連",
    "医療用具関連",
  ],
  "建築・土木技術職": [
    "プランニング・測量・設計・精算",
    "施工管理・設備・環境保全",
    "研究開発・技術開発・構造解析・特許",
  ],
  "技能工・設備・交通・運輸": [
    "技能工（整備・工場生産・製造・工事）",
    "運輸・運送・倉庫関連",
    "交通（鉄道・バス）関連",
    "警備・清掃・設備管理関連・その他",
  ],
  "医療・福祉・介護": ["医療サービス関連", "福祉・介護サービス・栄養"],
  "教育・保育・公務員・農林水産・その他": [
    "教育・保育・インストラクター・通訳・翻訳",
    "公務員・団体職員",
    "農林水産関連職",
    "その他職種",
  ],
};

export const industryChoices = {
  営業: [
    "法人営業",
    "個人営業",
    "ルート営業・ラウンダー",
    "テレフォンアポインター（テレアポ）",
    "営業アシスタント",
    "プランナー・アドバイザー・コンサルタント",
    "派遣コーディネーター",
    "営業幹部候補",
    "その他（営業）",
  ],
  "事務・オフィスワーク": [
    "一般事務",
    "経理事務",
    "営業事務",
    "受付・秘書",
    "伝票整理・データ入力",
    "電話対応・コールセンター",
    "人事・総務・法務",
    "企画・マーケティング・広報",
    "保険・金融関連",
    "その他（事務・オフィスワーク）",
  ],
  販売: [
    "アパレル・靴",
    "コンビニ",
    "スーパー・デパート",
    "家電量販店",
    "携帯ショップ・携帯販売",
    "インテリア・雑貨販売",
    "ドラッグストア・化粧品販売・登録販売者",
    "書店・レンタル・CDショップ",
    "ホームセンター・カー用品",
    "アクセサリー・ジュエリー",
    "フラワーショップ",
    "ペットショップ",
    "店長・マネージャー（販売）",
    "その他（販売）",
  ],
  "飲食・フード": [
    "ファミレス・レストラン",
    "ファーストフード",
    "居酒屋・ダイニングバー",
    "カフェ・喫茶店",
    "ラーメン・うどん・そば",
    "焼肉店・しゃぶしゃぶ店",
    "寿司・和食・定食",
    "フードコート",
    "パン屋・ケーキ屋・スイーツ店",
    "宅配・デリバリー",
    "お弁当・テイクアウトフード",
    "調理師・調理補助",
    "ホールスタッフ",
    "キッチンスタッフ",
    "ホール＋キッチン",
    "洗い場・食器洗浄",
    "試飲・試食",
    "店長・マネージャー（飲食・フード）",
    "その他（飲食・フード）",
  ],
  "サービス・警備・清掃": [
    "ショールーム・不動産",
    "ガソリンスタンド",
    "クリーニング店",
    "レンタカー・ディーラー・中古車販売",
    "旅行会社・ツアーコンダクター",
    "葬儀・セレモニー",
    "結婚式場・ブライダル",
    "新聞店",
    "引っ越し",
    "洗車・ピットサービス・タイヤ交換",
    "警備員・監視員",
    "交通誘導",
    "清掃員・ルームメイク",
    "ビル・ハウスクリーニング",
    "管理人",
    "検針員",
    "サンプリング",
    "フロント・受付サービス",
    "店長・マネージャー（サービス・警備・清掃）",
    "その他（サービス・警備・清掃）",
  ],
  "イベント・レジャー・娯楽": [
    "カラオケ",
    "パチンコ",
    "映画館・ゲームセンター・ボウリング場",
    "温泉・銭湯・スーパー銭湯",
    "ゴルフ場",
    "ジム・プール",
    "ホテル・旅館・ペンション",
    "スキー場",
    "イベント・キャンペーンスタッフ",
    "店長・マネージャー（イベント・レジャー・娯楽）",
    "その他（イベント・レジャー・娯楽）",
  ],
  "教育・カルチャー・スポーツ": [
    "塾講師・家庭教師",
    "保育士・幼稚園教諭",
    "インストラクター・コーチ",
    "試験監督",
    "その他（教育・カルチャー・スポーツ）",
  ],
  "理・美容": [
    "理容師",
    "美容師",
    "ヘアメイク・メイクアップ・着付け・スタイリスト",
    "エステ・ネイル",
    "リラクゼーション・セラピスト",
    "フロント・受付（理・美容）",
    "店長・マネージャー（理・美容）",
    "その他（理・美容）",
  ],
  "医療・介護・福祉": [
    "看護師",
    "准看護師",
    "看護助手",
    "助産師",
    "保健師",
    "医療事務・受付",
    "医療技術者",
    "介護福祉士",
    "ケアマネージャー",
    "介護職員・ヘルパー",
    "介護事務",
    "歯科衛生士・歯科助手",
    "薬剤師",
    "整体師・柔道整復師",
    "栄養士・管理栄養士",
    "施設内調理・調理補助",
    "作業療法士",
    "理学療法士",
    "児童指導員・放課後児童支援員",
    "相談員",
    "サービス提供責任者",
    "職業指導員・就労支援員",
    "その他（医療・介護・福祉）",
  ],
  "ドライバー・配達": [
    "小・中ドライバー",
    "大型ドライバー",
    "タクシー・ハイヤー・バス運転手",
    "バイク便",
    "ドライバー助手",
    "配車・点呼員",
    "送迎ドライバー",
    "宅配・配達",
    "軽貨物",
    "新聞配達・牛乳配達",
    "その他（ドライバー・配達）",
  ],
  "製造・工場・倉庫": [
    "組立・検査・仕分け・ピッキング・梱包",
    "軽作業",
    "食品製造・加工・検査・梱包",
    "機械加工・マシンオペレーター",
    "フォークリフト作業・玉掛け・クレーン作業",
    "機会メンテナンス",
    "品質管理・生産管理・工程管理",
    "設備保全・設備管理・点検",
    "内職・在宅ワーク",
    "ラインオペレーター",
    "CAD/CAMオペレーター",
    "溶接",
    "製品製造・加工",
    "その他（製造・工場・倉庫）",
  ],
  "IT・エンジニア": [
    "システムエンジニア（SE）・プログラマー",
    "OA関連",
    "ユーザーサポート",
    "その他（IT・エンジニア）",
  ],
  "クリエイティブ・編集・出版": [
    "WEBデザイナー",
    "デザイナー・イラストレーター",
    "DTPオペレーター",
    "設計・製図",
    "印刷・製本",
    "校正・編集",
    "ライター・記者・撮影スタッフ",
    "マスコミ関連",
    "その他（クリエイティブ・編集・出版）",
  ],
  専門職: [
    "調理師・栄養士",
    "税理士・会計士",
    "通訳・翻訳",
    "トリマー",
    "研究・開発関連",
    "マッサージ師・鍼師・鍼灸師",
    "整備士",
    "警察官・自衛官",
    "修理・メンテナンス",
    "塗装",
    "溶接・板金",
    "電気・水道・ガス・空調・設備工事",
    "造園",
    "その他（専門職）",
  ],
  "土木・建設・農水産": [
    "建築士",
    "土木工事関連",
    "重機オペレーター",
    "現場監督",
    "大工・鳶・職人",
    "玉掛け・クレーン",
    "農業・林業・漁業・畜産業",
    "除染作業員",
    "その他（土木・建設・農水産）",
  ],
  その他: ["その他"],
};
