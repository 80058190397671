import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HeaderMenu } from "../components/HeaderBar";
import { Title } from "../components/Title";
import { MiscContext } from "../contexts/Contexts";

const VoiceMediaItem = ({ onClick = () => {}, isMobile, thumbnail, title }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        maxWidth: isMobile ? 300 : 670,
      }}
      onClick={onClick}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          alt={title}
          src={thumbnail && thumbnail.src}
          style={{
            width: isMobile ? 107 : 214,
            height: isMobile ? 61 : 122,
            marginTop: isMobile ? 4 : 16,
            marginBottom: isMobile ? 4 : 16,
            borderRadius: 3,
          }}
        />
        <div
          style={{
            width: isMobile ? 169 : 420,
            marginLeft: isMobile ? 8 : 45,
          }}
        >
          <div style={{ fontWeight: 700, fontSize: isMobile ? 10 : 14 }}>{title}</div>
        </div>
      </div>
    </div>
  );
};

export const VoiceMediaListPage = () => {
  const { voiceMediumList } = useContext(MiscContext);
  const { isMobile } = useContext(MiscContext);
  const history = useHistory();
  const [orderedList, setOrderedList] = useState([]);

  useEffect(() => {
    setOrderedList([
      ...voiceMediumList.sort((a, b) => a.created_at.seconds > b.created_at.seconds),
    ]);
  }, [voiceMediumList]);

  return (
    <>
      <header>
        <HeaderMenu title="音声配信" />
      </header>
      <div
        style={
          isMobile
            ? {
                marginLeft: 24,
                marginRight: 24,
                paddingTop: 42,
              }
            : { marginTop: 108, marginLeft: 24, marginRight: 24 }
        }
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Title
            style={{
              textAlign: "left",
              marginBottom: isMobile ? 8 : 30,
              paddingTop: 20,
              fontSize: !isMobile && 20,
              width: isMobile ? 310 : 660,
            }}
            title="音声配信"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {orderedList.length > 0 ? (
            orderedList.map((voiceMedia, i) => {
              return (
                <VoiceMediaItem
                  onClick={() => history.push(`/voice_medium/${voiceMedia.id}`)}
                  isMobile={isMobile}
                  description={voiceMedia.description}
                  title={voiceMedia.title}
                  thumbnail={voiceMedia.thumbnail}
                />
              );
            })
          ) : (
            <>現在準備中です</>
          )}
        </div>
      </div>
    </>
  );
};
