import { web, mobile } from "../constants/colors";

export const Problem = ({ value, answer, updateAnswer, isMobile }) => {
  const unselected_option_style = {
    width: "100%",
    minHeight: 48,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    marginBottom: 10,
  };

  const selected_option_style = {
    width: "100%",
    minHeight: 48,
    borderRadius: 10,
    marginBottom: 10,
    color: isMobile ? "black" : web.secondary,
    backgroundColor: isMobile ? mobile.primary : web.primary,
  };

  const question_style = {
    width: "100%",
    minHeight: 60,
    borderRadius: 10,
    paddingTop: 10,
    paddingLeft: 20,
    marginBottom: 20,
    color: isMobile ? "black" : web.secondary,
    backgroundColor: isMobile ? mobile.primary : web.primary,
  };

  return (
    <div style={{ marginBottom: 40 }}>
      <div style={question_style}>
        <div style={{ fontSize: 16, fontWeight: 700 }}>{value.title}</div>
        <div style={{ fontSize: 12, fontWeight: 700, whiteSpace: "pre-wrap" }}>
          {value.question}
        </div>
      </div>
      <div style={{ textAlign: "left" }}>
        {value.choices.map((choice, i) => (
          <button
            onClick={() => updateAnswer(i)}
            style={i !== answer ? unselected_option_style : selected_option_style}
            key={i}
          >
            <div>
              {i + 1}
              {".    "}
              {choice}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
