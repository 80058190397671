import { AuthContext, AuthProvider } from "./AuthContext";
import { UsersContext, UsersProvider } from "./UsersContext";
import { MiscContext, MiscProvider } from "./MiscContext";
import { LessonsContext, LessonsProvider } from "./LessonsContext";
export const Providers = ({ children }) => {
  return (
    <AuthProvider>
      <MiscProvider>
        <UsersProvider>
          <LessonsProvider>{children}</LessonsProvider>
        </UsersProvider>
      </MiscProvider>
    </AuthProvider>
  );
};

export { AuthContext, UsersContext, LessonsContext, MiscContext };
