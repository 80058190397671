import { useState, useEffect, useContext } from "react";
import Parser from "html-react-parser";
import { Title } from "../components/Title";
import { HeaderMenu } from "../components/HeaderBar";
import { useParams } from "react-router-dom";
import { MiscContext } from "../contexts/Contexts";

export const VoiceMediaPage = () => {
  const { id } = useParams();
  const { voiceMedium } = useContext(MiscContext);
  const [lesson, setLesson] = useState({});
  const { isMobile, dimensions } = useContext(MiscContext);

  useEffect(() => {
    setLesson(voiceMedium[id]);
  }, [voiceMedium, id]);

  return (
    <>
      <header>
        <HeaderMenu title={lesson.title} />
      </header>
      <div
        style={
          isMobile
            ? {
                height: "100%",
                paddingTop: 40,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: window.innerHeight,
                marginLeft: 24,
                marginRight: 24,
              }
            : {
                marginTop: 100,
                paddingTop: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }
        }
      >
        <div>
          <iframe
            style={{
              boxShadow: "0 6px 10px #999999",
              width: Math.min(dimensions.width, 564),
              height: (Math.min(dimensions.width, 564) / 16) * 9,
            }}
            src={`https://www.youtube.com/embed/${lesson.youtube_id}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: 24,
            marginRight: 24,
            width: "100%",
            maxWidth: 564,
          }}
        >
          <div
            style={{
              maxWidth: 564,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <div>
              <a
                style={{ fontSize: 12, color: "#373E4D" }}
                href={`https://www.youtube.com/watch?v=${lesson.youtube_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTubeで見る
              </a>
            </div>
          </div>
          <div style={{ marginTop: 16 }}>
            <Title title={lesson.title} />
          </div>
          <div
            style={{
              width: "100%",
              marginTop: 8,
              fontSize: isMobile ? 10 : 14,
              textAlign: "left",
            }}
          >
            {lesson.description && Parser("<div>" + lesson.description + "</div>")}
          </div>
        </div>
      </div>
    </>
  );
};
