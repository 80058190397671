import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../contexts/Contexts";
import { PAYMENT_URL } from "../constants/external_links";

export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { loggedIn, initialized, emailVerified, logout } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        // Logged in?
        if (!initialized) {
          return <></>;
        }
        if (loggedIn) {
          if (!emailVerified) {
            logout();
            window.location.href = `${PAYMENT_URL}/#/send_verification_email`;
            return;
          }
          return <RouteComponent {...routeProps} />;
        } else {
          return <Redirect to={"/login"} />;
        }
      }}
    />
  );
};
