import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { User } from "../components/User";
import { PersonalData } from "../components/PersonalData";
import { Hashtag } from "../components/Hashtag";
import { Button } from "../components/Button";
import { UsersContext, MiscContext, AuthContext } from "../contexts/Contexts";
import { SnsIconLink } from "../components/SnsIconLink";
import { AtSymbolIcon } from "@heroicons/react/outline";
import TelImg from "../assets/images/tel.png";
import MailImg from "../assets/images/email.png";
import toast from "react-hot-toast";

let sns_list = ["instagram", "facebook", "twitter", "line", "tiktok", "youtube"];

export const MyPage = () => {
  const { isMobile } = useContext(MiscContext);
  const { ownUserInfo } = useContext(UsersContext);
  const { isValidPaidAccount } = useContext(AuthContext);
  const contactImgSrc = {
    phone: (
      <img
        alt="tel"
        src={TelImg}
        style={{
          width: isMobile ? 58 : 80,
          height: isMobile ? 58 : 80,
        }}
      />
    ),
    email: (
      <img
        alt="email"
        src={MailImg}
        style={{
          width: isMobile ? 58 : 80,
          height: isMobile ? 58 : 80,
        }}
      />
    ),
    other: <AtSymbolIcon />,
  };
  const history = useHistory();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ paddingTop: isMobile ? 0 : 68 }}>
            {isValidPaidAccount && (
              <div style={{ paddingTop: isMobile ? 20 : 50 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/membership.png`}
                  style={{
                    width: isMobile ? 327 : 654,
                    height: isMobile ? 185 : 370,
                    borderRadius: 10,
                  }}
                  alt=""
                />
              </div>
            )}
          </div>
          <div>
            {!isMobile && (
              <Button
                style={{ width: "100%", marginTop: 76 }}
                label="プロフィールを編集する"
                onClick={() => {
                  history.push("/mypage_edit");
                }}
              />
            )}
          </div>
          <div style={{ marginTop: 30 }}>
            <User
              isMobile={isMobile}
              avatar={ownUserInfo.avatar}
              name={ownUserInfo.family_name + " " + ownUserInfo.given_name}
              industry={ownUserInfo.industry}
              attribute={ownUserInfo.attribute}
            />
          </div>
        </div>
        <div style={{ width: isMobile ? 327 : 654 }}>
          <div style={{ marginLeft: 24, marginTop: 16, marginRight: 24 }}>
            <PersonalData
              isMobile={isMobile}
              sex={ownUserInfo.gender}
              birthday={ownUserInfo.birthdate}
              hub={ownUserInfo.area}
              Selfintroduction={ownUserInfo.introduction}
            />
          </div>
          <div style={{ marginLeft: 24, marginTop: 8, marginRight: 24 }}>
            <div style={{ fontSize: isMobile ? 12 : 16, marginBottom: isMobile ? 0 : 20 }}>
              興味
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {ownUserInfo.interests.map((item) => {
                return (
                  <div style={{ textAlign: "center", marginTop: 2, marginRight: 3 }}>
                    <Hashtag value={item} isMobile={isMobile} />
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ marginLeft: 24, marginTop: 8 }}>
            <div style={{ fontSize: isMobile ? 12 : 16, marginBottom: isMobile ? 0 : 20 }}>
              連絡先
            </div>
            <div style={{ display: "flex", marginLeft: -4 }}>
              {Object.keys(ownUserInfo.contacts).map((key) => {
                return (
                  <div
                    onClick={() => {
                      navigator.clipboard
                        .writeText(ownUserInfo.contacts[key].value)
                        .then(() => toast.success("コピーしました"));
                    }}
                    style={{
                      borderRadius: 10,
                      width: isMobile ? 58 : 80,
                      height: isMobile ? 58 : 80,
                      marginLeft: isMobile ? 10 : 7,
                      fontSize: 36,
                      fontWeight: 700,
                      cursor: "pointer",
                    }}
                  >
                    {contactImgSrc[ownUserInfo.contacts[key].type]}
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ marginLeft: 24, marginTop: 4 }}>
            <div style={{ fontSize: isMobile ? 12 : 16, marginBottom: isMobile ? 0 : 20 }}>
              その他SNS
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {sns_list.map((sns_name) => {
                return ownUserInfo.sns_data[sns_name] ? (
                  <SnsIconLink
                    sns={sns_name}
                    style={{ width: isMobile ? 58 : 80 }}
                    username={ownUserInfo.sns_data[sns_name]}
                  />
                ) : null;
              })}
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          {isMobile && (
            <Button
              style={{ width: "100%" }}
              label="プロフィールを編集する"
              onClick={() => {
                history.push("/mypage_edit");
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
