import React from "react";
import { Link } from "react-router-dom";

export const CategoryItem = ({
  isMobile,
  width,
  height,
  fontSize,
  thumbnail,
  name,
  onClick = () => {
    /* nothings */
  },
}) => {
  return (
    <div onClick={onClick} style={{ width: isMobile && 100 }}>
      <div style={{textAlign: "center"}}>
        <img
          src={thumbnail && thumbnail.src}
          style={{
            marginLeft: 5,
            marginRight: 5,
            width: isMobile ? 95 : width,
            height: isMobile ? 145 : height,
            objectFit: "cover",
            borderRadius: 3,
          }}
          alt={thumbnail && thumbnail.title}
        ></img>
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: isMobile ? 12 : fontSize,
          fontWeight: "700",
        }}
      >
        {name}
      </div>
    </div>
  );
};

export const CategoryList = ({ values }) => (
  <>
    {values.map((v) => (
      <CategoryItem {...v} key={v.id} />
    ))}
  </>
);

export const SubjectItem = ({ isMobile, thumbnail, title, description, id }) => {
  return (
    <>
      <Link style={{ color: "black", textDecoration: "none" }} to={`/subjects/${id}`}>
        <div>
          <img
            src={thumbnail && thumbnail.src}
            style={{
              width: isMobile ? 155 : 310,
              height: isMobile ? 88 : 176,
              objectFit: "cover",
              borderRadius: 3,
            }}
            alt={thumbnail && thumbnail.title}
          ></img>
        </div>
        <div
          style={
            isMobile
              ? {
                  fontSize: 12,
                  fontWeight: 700,
                  width: 155,
                  wrapText: true,
                }
              : {
                  fontWeight: 700,
                  width: 300,
                  wrapText: true,
                }
          }
        >
          {title}
        </div>
        <div
          style={
            isMobile
              ? { fontSize: 8, textDecoration: "none", width: 155, overflow: "hidden", height: 24 }
              : { width: 300, height: 128, overflow: "hidden" }
          }
        >
          {description}
        </div>
      </Link>
    </>
  );
};
