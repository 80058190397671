import React from "react";
import InstagramIcon from "../assets/icons/instagram.png";
import FacebookIcon from "../assets/icons/facebook.png";
import TwitterIcon from "../assets/icons/twitter.png";
import TiktokIcon from "../assets/icons/tiktok.png";
import YoutubeIcon from "../assets/icons/youtube.png";
import LineIcon from "../assets/icons/line.png";

const iconSize = 50;
const iconStyle = {
  width: `${iconSize}px`,
  height: `${iconSize}px`,
  margin: 4,
  borderRadius: 3,
};

export const SnsIconLink = ({ sns, link, username, ...args }) => {
  const links = {
    instagram: "https://instagram.com/",
    facebook: "https://www.facebook.com/",
    twitter: "https://twitter.com/",
    tiktok: "https://tiktok.com/@",
    line: "https://line.me/R/ti/p/",
    youtube: "",
  };

  const icons = {
    instagram: InstagramIcon,
    facebook: FacebookIcon,
    twitter: TwitterIcon,
    tiktok: TiktokIcon,
    line: LineIcon,
    youtube: YoutubeIcon,
  };

  return (
    <div style={{ margin: 4 }}>
      <a rel="noreferrer" target="_blank" href={`${links[sns]}${username}`}>
        <img src={icons[sns]} style={iconStyle} {...args} alt="snsimg"></img>
      </a>
    </div>
  );
};

export { InstagramIcon, FacebookIcon, TwitterIcon, TiktokIcon, YoutubeIcon, LineIcon };
