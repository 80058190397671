import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MiscContext, AuthContext } from "../contexts/Contexts";
import { InputBox } from "../components/InputBox";
import { Button } from "../components/Button";
import { PAYMENT_URL } from "../constants/external_links";
import { web } from "../constants/colors";
import toast from "react-hot-toast";
import Logo from "../assets/images/ENCOLN_W.png";

export const LoginPage = () => {
  const { isMobile } = useContext(MiscContext);
  const { login, loggedIn } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      history.push("/");
      window.history.go();
    }
  }, [loggedIn, history]);

  const handleLogin = () => {
    login({ email, password })
      .then(() => {
        toast.success("ログインしました。");
      })
      .catch((e) => {
        toast.error("ログインに失敗しました。");
      });
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    margin: "0",
  };

  return (
    <>
      <div
        style={
          isMobile
            ? {
                backgroundColor: web.secondary,
                ...containerStyle,
              }
            : {
                backgroundColor: web.secondary,
                ...containerStyle,
              }
        }
      >
        {isMobile && <img src={Logo} style={{ marginTop: 120, width: 280 }} alt="encoln-logo" />}
        <div style={{ marginTop: isMobile ? 40 : 160 }}>
          <InputBox placeholder="ID" onChange={setEmail} />
        </div>
        <div style={{ marginTop: 16 }}>
          <InputBox placeholder="パスワード" onChange={setPassword} type="password" />
        </div>
        <div>
          <div style={isMobile ? { marginTop: 8 } : { marginTop: 8 }}>
            {/* TODO Bring it to right*/}
            <a
              style={
                isMobile
                  ? {
                      color: web.primary,
                      fontSize: 14,
                    }
                  : {
                      color: web.primary,
                      fontSize: 14,
                      textDecoration: "none",
                    }
              }
              href={`${PAYMENT_URL}/#/forgot_password`}
            >
              <div>パスワードを忘れた方はこちら</div>
            </a>
          </div>
        </div>
        <div style={{ marginTop: 8 }}>
          <Button
            style={isMobile && { width: 327 }}
            onClick={() => handleLogin()}
            disabled={!password}
            label="ログイン"
          />
        </div>
        {isMobile ? (
          <div style={isMobile ? { marginTop: 16 } : {}}>
            <a
              style={
                isMobile
                  ? {
                      color: web.primary,
                      fontSize: 14,
                    }
                  : { color: web.primary }
              }
              href={`${PAYMENT_URL}/#/signup`}
            >
              <div>新規入会される方</div>
            </a>
          </div>
        ) : (
          <div style={{ marginTop: 16 }}>
            <Button
              style={{
                backgroundColor: "white",
                color: web.primary,
              }}
              onClick={() => {
                window.location.href = `${PAYMENT_URL}/#/signup`;
              }}
              label={"新規入学"}
            />
          </div>
        )}
        <a
          href="https://www.encoln.com/post/encoln-admission"
          style={
            isMobile
              ? {
                  color: web.primary,
                  fontSize: 14,
                }
              : {
                  color: web.primary,
                  fontSize: 14,
                  textDecoration: "none",
                }
          }
        >
          お問い合わせ
        </a>
      </div>
    </>
  );
};
