import { useState, useContext, useEffect, useMemo } from "react";
import { Title } from "../components/Title";
import { HeaderMenu } from "../components/HeaderBar";
import { Search } from "../components/Search";
import { DropDownList } from "../components/DropDownList";
import { SubjectItem } from "../components/FindSubjectItems";
import { LessonsContext, MiscContext } from "../contexts/Contexts";
import { useHistory, useLocation } from "react-router-dom";

export const FindSubjectPage = () => {
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  // From Contexts
  const { instructorsList, topicsList } = useContext(MiscContext);
  const { findSubjectBy } = useContext(LessonsContext);

  // State for this page
  const [filteredSubjects, setFilteresdSubjects] = useState([]);
  const [searchedTitle, setSearchedTitle] = useState(query.get("title"));
  const [instructor, setInstructor] = useState({ value: query.get("instructor") });
  const selectedCategory = useMemo(() => query.get("category"), [query]);
  const [topic, setTopic] = useState({ value: query.get("topic") });
  const genre = "学科";
  const { isMobile } = useContext(MiscContext);

  useEffect(() => {
    console.debug(instructor);
    findSubjectBy({
      topic: topic.value,
      instructor: instructor.value,
      category: selectedCategory,
      title: searchedTitle,
    }).then((v) => {
      setFilteresdSubjects(v);
    });
  }, [selectedCategory, topic, instructor, findSubjectBy, searchedTitle]);

  const instructorOptions = useMemo(
    () => [
      { label: "講師を選択", value: "" },
      ...instructorsList.map((item) => ({ label: item.full_name, value: item.id })),
    ],
    [instructorsList]
  );

  const topicOptions = useMemo(
    () => [
      { label: "トピックを選択", value: "" },

      ...topicsList.map((item) => ({ label: item.name, value: item.id })),
    ],
    [topicsList]
  );

  return (
    <>
      <HeaderMenu title="講義" onClick={() => history.goBack()} />
      <div style={{ paddingTop: isMobile ? 40 : 120 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: isMobile ? 24 : 72,
            marginRight: isMobile ? 24 : 72,
          }}
        >
          {!isMobile && (
            <div>
              <Title title={genre} style={isMobile ? {} : { fontSize: 32 }} />
            </div>
          )}
          <div style={isMobile ? { width: "100%" } : {}}>
            <Search
              placeholder="学科を検索"
              style={isMobile && { width: "100%" }}
              value={searchedTitle}
              onChange={setSearchedTitle}
              autoFocus={true}
            />
          </div>
        </div>
        {isMobile && (
          <div style={{ marginLeft: 24, marginBottom: 6 }}>
            <Title title={genre} />
          </div>
        )}
        <div
          style={{
            display: "flex",
            marginLeft: isMobile ? 16 : 72,
            marginRight: isMobile ? 24 : 72,
          }}
        >
          <div>
            <DropDownList
              options={instructorOptions}
              values={instructor}
              onChange={setInstructor}
              searchBy="full_name"
              labelField="full_name"
              valueField="id"
              clearable={true}
              defaultValue={instructorOptions.find(({ value }) => value === instructor.value)}
            />
          </div>
          <div>
            <DropDownList
              options={topicOptions}
              values={topic}
              onChange={setTopic}
              defaultValue={topicOptions.find(({ value }) => topic.value === value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            marginTop: isMobile ? 20 : 60,
            marginLeft: isMobile ? 16 : 72,
            marginRight: isMobile ? 16 : 72,
          }}
        >
          {filteredSubjects.length > 0 &&
            filteredSubjects.map(({ id, thumbnail, title, description }) => (
              <SubjectItem
                isMobile={isMobile}
                title={title}
                description={description}
                thumbnail={thumbnail}
                key={id}
                id={id}
              />
            ))}
        </div>
      </div>
    </>
  );
};
