import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { Title } from "../components/Title";
import { HeaderMenu } from "../components/HeaderBar";
import { PreviewItem } from "../components/PreviewItem";
import { Calendar } from "../components/Calendar";
import { useHistory } from "react-router-dom";
import { MiscContext, AuthContext } from "../contexts/Contexts";
import { addDays, subDays, isSameDay } from "date-fns/fp";
import { startOfToday } from "date-fns";
import firebase from "../firebase_exports";

const toDate = (datetime) =>
  new firebase.firestore.Timestamp(datetime.seconds, datetime.nanoseconds).toDate();
export const FindByDatePage = () => {
  const [date, setDate] = useState(startOfToday());
  const isCurrentDay = isSameDay(date);
  const [livestreams, setLivestreams] = useState([]);
  const [events, setEvents] = useState([]);
  const { listEventsOf, listLiveStreamEventsOf, isMobile } = useContext(MiscContext);

  const interval = useMemo(() => ({ start: subDays(3)(date), end: addDays(3)(date) }), [date]);
  const todayEvents = useMemo(
    () => events.filter((e) => isCurrentDay(toDate(e.datetime))),
    [events, isCurrentDay]
  );
  const todayLivestreams = useMemo(
    () => livestreams.filter((e) => isCurrentDay(toDate(e.datetime))),
    [livestreams, isCurrentDay]
  );
  const highlightDays = useMemo(
    () => [...events.map((e) => toDate(e.datetime)), ...livestreams.map((e) => toDate(e.datetime))],
    [events, livestreams]
  );

  const { loggedIn, isValidPaidAccount, setShowUpgradeModal } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (loggedIn && !isValidPaidAccount) {
      setShowUpgradeModal(true);
      history.push("/");
    }
  }, [isValidPaidAccount, setShowUpgradeModal, history, loggedIn]);
  const placeholderStyle = isMobile
    ? {
        height: 300,
      }
    : {
        height: 386,
      };
  const setupItemsOfDay = useCallback(async () => {
    let items = await listEventsOf({
      begin: interval.start,
      end: addDays(1)(interval.end),
    });
    setEvents(items);
    let live_streams = await listLiveStreamEventsOf({
      begin: interval.start,
      end: addDays(1)(interval.end),
    });
    setLivestreams(live_streams);
    return;
  }, [listEventsOf, listLiveStreamEventsOf, interval]);
  useEffect(() => {
    setupItemsOfDay();
  }, [setupItemsOfDay]);
  return (
    <>
      <header>
        <HeaderMenu title="日付で探す" />
      </header>
      <div
        style={{
          width: "100vw",
          height: "100%",
          backgroundColor: "#F1F1FA",
          margin: isMobile ? `0` : `100px 24px`,
          padding: 0,
        }}
      >
        <div
          style={{
            paddingTop: 40,
            margin: `0px`,
            borderBottom: "1px solid #C4C4C4",
          }}
        >
          <Calendar
            onChange={setDate}
            value={date}
            isMobile={isMobile}
            interval={interval}
            highlightDays={highlightDays}
          />
        </div>
        <div
          style={{
            paddingTop: isMobile ? 40 : 120,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginTop: 16 }}>
            <Title title="LIVE配信" />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {todayLivestreams.length < 1 && (
                <div style={placeholderStyle}>予定されているLIVE配信はありません</div>
              )}
              {todayLivestreams.map((item) => (
                <PreviewItem
                  link={`/live_streams/${item.id}`}
                  title={item.title}
                  datetime={item.datetime}
                  description={item.description}
                  imgsrc={item.thumbnail}
                />
              ))}
            </div>
          </div>
          <div>
            <Title title="イベント" />
            <div>
              {todayEvents.length < 1 && (
                <div style={placeholderStyle}>予定されているイベントはありません</div>
              )}
              {todayEvents.map((item) => (
                <PreviewItem
                  link={`/events/${item.id}`}
                  title={item.title}
                  datetime={item.datetime}
                  description={item.description}
                  imgsrc={item.thumbnail}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
