import { createSvgIcon } from "@material-ui/core/utils";

export default createSvgIcon(
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0003 2.74984C6.44398 2.74984 2.75033 6.44349 2.75033 10.9998C2.75033 15.5562 6.44398 19.2498 11.0003 19.2498C15.5567 19.2498 19.2503 15.5562 19.2503 10.9998C19.2503 6.44349 15.5567 2.74984 11.0003 2.74984ZM0.916992 10.9998C0.916992 5.43097 5.43145 0.916504 11.0003 0.916504C16.5692 0.916504 21.0837 5.43097 21.0837 10.9998C21.0837 16.5687 16.5692 21.0832 11.0003 21.0832C5.43145 21.0832 0.916992 16.5687 0.916992 10.9998Z"
      fill="#FCFCFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.73413 6.52497C9.03219 6.36545 9.39386 6.38294 9.67514 6.57046L15.1751 10.2371C15.4302 10.4071 15.5833 10.6933 15.5833 10.9998C15.5833 11.3063 15.4302 11.5925 15.1751 11.7626L9.67514 15.4292C9.39386 15.6167 9.03219 15.6342 8.73413 15.4747C8.43607 15.3152 8.25 15.0046 8.25 14.6665V7.33317C8.25 6.99511 8.43607 6.68449 8.73413 6.52497ZM10.0833 9.04598V12.9537L13.0141 10.9998L10.0833 9.04598Z"
      fill="#FCFCFF"
    />
  </>
);
