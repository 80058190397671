import { web } from "../constants/colors";
export const WebBlock = ({ children, style, secondary = false }) => {
  const blockStyle = {
    textAlign: "center",
    width: "100%",
  };
  return (
    <div
      style={
        secondary
          ? {
              backgroundColor: "#F1F1FA",
              ...blockStyle,
              ...style,
            }
          : {
              height: 800,
              backgroundColor: web.primary,
              ...blockStyle,
              ...style,
            }
      }
    >
      {children}
    </div>
  );
};
