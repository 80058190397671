import React, { useContext } from "react";
import { AuthContext, MiscContext } from "../contexts/Contexts";
import { Button } from "./Button";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import { web, mobile } from "../constants/colors";

export const LessonModal = ({ img_src, label, description, lesson }) => {
  return (
    <div style={{ zIndex: 2 }}>
      <img src={img_src} alt={label} />
      {/* More like video */}
      <div>
        <p>{description}</p>
      </div>
    </div>
  );
};

export const LessonAccessModal = ({ open, setOpen, isMobile, nextLesson, ...args }) => {
  const modalStyle = {
    position: "fixed",
    border: "1px solid white",
    height: 184,
    top: "30%",
    bottom: "30%",
    left: isMobile ? "10%" : "30%",
    right: isMobile ? "10%" : "30%",
    backgroundColor: "#00000000",
  };
  return (
    <Modal open={open} style={modalStyle} {...args}>
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 10,
          paddingBottom: 20,
          paddingTop: 20,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div>
          <div>前動画のテストに合格すると</div>
          <div>動画を視聴できます</div>
        </div>
        <div>
          <Link to={`/lessons/${nextLesson}`}>
            <div style={{ color: "black" }}>前の動画を視聴する</div>
          </Link>
        </div>
        <div style={{ width: "100%" }}>
          <Button
            style={
              isMobile
                ? {
                    marginRight: "10%",
                    marginLeft: "10%",
                    width: "80%",
                    height: 40,
                    border: `1px solid ${mobile.primary}`,
                    backgroundColor: "white",
                    color: "black",
                  }
                : {
                    marginRight: "10%",
                    marginLeft: "10%",
                    width: "80%",
                    height: 40,
                    border: `1px solid ${web.primary}`,
                    backgroundColor: "white",
                    color: web.primary,
                  }
            }
            onClick={() => setOpen(false)}
            label="戻る"
          />
        </div>
      </div>
    </Modal>
  );
};

export const UpgradeModal = ({ ...args }) => {
  const { isMobile } = useContext(MiscContext);
  const modalStyle = {
    position: "fixed",
    border: "1px solid white",
    top: "30%",
    bottom: "30%",
    left: isMobile ? "10%" : "30%",
    right: isMobile ? "10%" : "30%",
    backgroundColor: "#00000000",
  };
  const { showUpgradeModal, setShowUpgradeModal } = useContext(AuthContext);
  return (
    <Modal open={showUpgradeModal} style={modalStyle} {...args}>
      <div
        style={{
          height: "100%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 10,
          paddingBottom: 20,
          paddingTop: 20,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div style={{ fontSize: 16 }}>
          有料会員へアップグレードするとすべての機能を使用できます。
        </div>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
          <Button
            onClick={() => window.location.assign("https://payment.encolnschool.com/#/login")}
            style={{ width: "40%", height: 40, fontSize: 12 }}
            label="決済ページへ"
          />
          <Button
            secondary
            style={
              isMobile
                ? {
                    width: "40%",
                    height: 40,
                    border: `1px solid ${mobile.primary}`,
                    backgroundColor: "white",
                    color: "black",
                    fontSize: 12,
                  }
                : {
                    width: "40%",
                    height: 40,
                    border: `1px solid ${web.primary}`,
                    backgroundColor: "white",
                    color: web.primary,
                    fontSize: 12,
                  }
            }
            label="閉じる"
            onClick={() => setShowUpgradeModal(false)}
          />
        </div>
      </div>
    </Modal>
  );
};
