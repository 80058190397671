import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import { NavbarWeb } from "./components/HeaderBar";
import { FooterBar } from "./components/FooterBar";
import { Providers } from "./contexts/Contexts";
import { routing } from "./constants/routing";
import { Toaster } from "react-hot-toast";
import { UpgradeModal } from "./components/LessonModal";
import ScrollToTop from "./components/ScrollToTop";
function App() {
  return (
    <div
      style={{
        backgroundColor: "#F1F1FA",
        width: "100%",
        height: "100%",
        margin: 0,
        minHeight: window.innerHeight,
      }}
    >
      <Router>
        <ScrollToTop />
        <Providers>
          <NavbarWeb />
          <Toaster />
          <UpgradeModal />
          <Switch>
            <div style={{ paddingBottom: "70px" }}>
              {routing.map((item) => {
                return item.private ? (
                  <PrivateRoute
                    key={item.path}
                    exact={item.exact}
                    component={item.component}
                    path={item.path}
                  />
                ) : (
                  <Route
                    key={item.path}
                    exact={item.exact}
                    component={item.component}
                    path={item.path}
                  />
                );
              })}
            </div>
          </Switch>
          <footer>
            <FooterBar />
          </footer>
        </Providers>
      </Router>
    </div>
  );
}

export default App;
