import { firestore } from "../firebase_exports";
import { useState, useEffect, createContext, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { userInitialState } from "../constants/user_choices";

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const collection_name = "users";
  const db = firestore;

  const { user } = useContext(AuthContext);

  const [usersList, setUsersList] = useState([]);
  const [users, setUsers] = useState({});
  const [ownUserInfo, setOwnUserInfo] = useState(userInitialState);

  useEffect(() => {
    db.collection(collection_name)
      .orderBy("family_name_kana")
      .startAt("あ")
      .limit(12)
      .onSnapshot((snapshot) => {
        let items = snapshot.docs.map((doc) => doc.data());
        let tmp = {};
        snapshot.docs.forEach((doc) => {
          tmp[doc.data().id] = doc.data();
        });
        setUsers(tmp);
        setUsersList(items);
      });
  }, [db]);

  useEffect(() => {
    if (user) {
      db.collection(collection_name)
        .doc(user.uid)
        .get()
        .then((data) => {
          setOwnUserInfo(data.data());
        });
      // setOwnUserInfo({ ...users[user.uid] });
    } else {
      setOwnUserInfo(userInitialState);
    }
  }, [user, db]);

  const loadMoreUsers = async () => {
    let lastVisible = usersList[usersList.length - 1];
    return db
      .collection(collection_name)
      .orderBy("family_name_kana")
      .startAfter(lastVisible.family_name_kana)
      .limit(12)
      .get()
      .then((snapshot) => {
        // TODO There is nothing in here even though there should be
        let items = snapshot.docs.map((doc) => doc.data());
        let tmp = { ...users };
        snapshot.docs.forEach((doc) => {
          tmp[doc.data().id] = doc.data();
        });
        setUsers(tmp);
        setUsersList([...usersList, ...items]);
      });
  };

  const queryUserBy = async ({ attribute, value }) => {
    let tmp = [];
    db.collection(collection_name)
      .where(attribute, "=", value)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((item) => tmp.push(item.data()));
        return tmp;
      })
      .catch((e) => {
        console.error(e);
        return tmp;
      });
  };

  const queryUserByMulti = async (filters) => {
    let collection = db.collection(collection_name);
    let nameFilter = {};
    let queries = [];
    for (let filter of filters) {
      if (filter.value !== "") {
        if (filter.label !== "name") {
          collection = collection.where(filter.label, filter.cond, filter.value);
        } else nameFilter = filter;
      }
    }
    // If there is a text in name, then search it through with all the fields
    // TODO: Do something with nameFilter
    if (nameFilter.value) {
      queries.push(
        collection
          .orderBy("family_name")
          .startAt(nameFilter.value)
          .endAt(`${nameFilter.value}\uf8ff`)
          .get()
      );
      queries.push(
        collection
          .orderBy("given_name")
          .startAt(nameFilter.value)
          .endAt(`${nameFilter.value}\uf8ff`)
          .get()
      );
      queries.push(
        collection
          .orderBy("family_name_kana")
          .startAt(nameFilter.value)
          .endAt(`${nameFilter.value}\uf8ff`)
          .get()
      );
      queries.push(
        collection
          .orderBy("given_name_kana")
          .startAt(nameFilter.value)
          .endAt(`${nameFilter.value}\uf8ff`)
          .get()
      );

      let snapshots = await Promise.all(queries);
      let results = snapshots.map((snapshot) => snapshot.docs.map((item) => item.data())).flat(1);

      // If there is a better way, then that would be nice to use
      let result = [];
      let map = new Map();
      for (let item of results) {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          result.push(item);
        }
      }

      return result;
    } else {
      collection = collection.orderBy("family_name_kana").startAt("あ");
      let usersQuery = await collection.get();
      let results = usersQuery.docs.map((item) => item.data());

      return results;
    }
  };

  const updateOwnUserInfo = (new_values) => {
    if (user) {
      db.collection(collection_name)
        .doc(user.uid)
        .update({ ...new_values });
      setOwnUserInfo({ ...new_values });
    }
  };

  const fetchUserInfo = async (user_id) => {
    const res = await db.collection(collection_name).doc(user_id).get();
    return res.data();
  };

  return (
    <UsersContext.Provider
      value={{
        ownUserInfo,
        users,
        usersList,
        updateOwnUserInfo,
        loadMoreUsers,
        queryUserBy,
        queryUserByMulti,
        fetchUserInfo,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
