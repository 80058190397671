import React, { useContext } from "react";
import { MiscContext } from "../contexts/Contexts";
import firebase from "../firebase_exports";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { web } from "../constants/colors";
// import Parser from "html-react-parser";

const lecturestyle = {
  width: 240,
  height: 268,
  backgroundColor: "#ffffff",
  borderRadius: 3,
};

const imgstyle = {
  width: 240,
  height: 135,
  objectFit: "cover",
};

const blackstyle = {
  color: "#161719",
  whiteSpace: "pre-wrap",
};

export const PreviewItem = ({
  link = "/",
  imgsrc,
  title = "",
  datetime = { seconds: 0, nanoseconds: 0 },
  secondary = false,
  // description,
}) => {
  const { isMobile } = useContext(MiscContext);
  const timestamp = new firebase.firestore.Timestamp(
    datetime.seconds,
    datetime.nanoseconds
  ).toDate();
  return (
    <>
      {isMobile ? (
        <div style={lecturestyle}>
          <Link to={link} style={{ textDecoration: "none" }}>
            <img src={imgsrc && imgsrc.src} style={imgstyle} alt={imgsrc && imgsrc.name}></img>
            <div style={{ marginTop: 10 }}>
              <div style={{ ...blackstyle, fontSize: 16, fontWeight: 700 }}>{title}</div>
            </div>
            {datetime.seconds !== 0 && (
              <div>
                <div style={{ ...blackstyle, fontSize: 12 }}>
                  {format(timestamp, "yyyy年MM月dd日 HH:mm~")}
                </div>
              </div>
            )}
          </Link>
        </div>
      ) : (
        <>
          <div style={{ marginLeft: 49, marginRight: 49, textAlign: "left" }}>
            <Link
              to={link}
              style={
                secondary
                  ? { textDecoration: "none", color: web.secondary }
                  : { color: web.primary, textDecoration: "none" }
              }
            >
              <img
                src={imgsrc && imgsrc.src}
                style={{ width: 495, height: 278, objectFit: "cover" }}
                alt={imgsrc && imgsrc.name}
              ></img>
              <div style={{ height: 70, maxWidth: 495, overflow: "hidden" }}>
                <div style={{ fontWeight: 700, fontSize: 24 }}>{title}</div>
              </div>
              <div style={{ height: 25, display: "flex", justifyContent: "space-between" }}>
                <div>{format(timestamp, "yyyy年MM月dd日 HH:mm~")}</div>
              </div>
            </Link>
          </div>
        </>
      )}
    </>
  );
};
