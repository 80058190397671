import React from "react";
import { useContext } from "react";
import { MiscContext } from "../contexts/Contexts";
import { web } from "../constants/colors";

const ButtonStyle = {
  mobile: {
    backgroundColor: web.primary,
    color: web.secondary,
    borderRadius: 10,
    borderWidth: 0,
    height: 40,
    fontSize: 16,
    fontWeight: 700,
  },
  web: {
    backgroundColor: web.primary,
    color: web.secondary,
    borderColor: web.primary,
    borderRadius: 6,
    borderWidth: 1,
    height: 52,
    width: 303,
    fontSize: 13,
    fontWeight: 900,
  },
};

export const Button = ({ label, disabled, onClick, style }) => {
  const { isMobile } = useContext(MiscContext);
  return (
    <button
      disabled={disabled}
      style={isMobile ? { ...ButtonStyle.mobile, ...style } : { ...ButtonStyle.web, ...style }}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
