import { useState, useContext, useEffect, useRef } from "react";
import { Title } from "../components/Title";
import { HeaderMenu } from "../components/HeaderBar";
import { Search } from "../components/Search";
import { DropDownList } from "../components/DropDownList";
import { SubjectItem } from "../components/FindSubjectItems";
import { LessonsContext, MiscContext } from "../contexts/Contexts";
import { useHistory, useParams } from "react-router-dom";

export const SubjectListPage = () => {
  // From Contexts
  const { instructorsList, topicsList } = useContext(MiscContext);
  const { findSubjectByCategory } = useContext(LessonsContext);

  // State for this page
  const [filteredSubjects, setFilteresdSubjects] = useState([]);
  const [searchedTitle, setSearchedTitle] = useState("");
  const [instructor, setInstructor] = useState({ value: "" });
  const { categoryId: selectedCategory } = useParams();
  const [topic, setTopic] = useState({ value: "" });
  const genre = "学科";
  const { isMobile } = useContext(MiscContext);

  const history = useHistory();

  useEffect(() => {
    findSubjectByCategory(selectedCategory).then((v) => {
      setFilteresdSubjects(v);
    });
  }, [selectedCategory, findSubjectByCategory]);

  const isFirst = useRef(true);

  useEffect(() => {
    console.debug(isFirst.current, instructor.value, topic.value, searchedTitle);
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }
    const query = new URLSearchParams();
    query.set("instructor", instructor.value);
    query.set("topic", topic.value);
    query.set("title", searchedTitle);
    query.set("category", selectedCategory);

    history.push({
      pathname: "/find_subjects",
      search: query.toString(),
    });
  }, [history, topic, instructor, searchedTitle, selectedCategory]);

  return (
    <>
      <HeaderMenu title="講義" onClick={() => history.goBack()} />
      <div style={{ paddingTop: isMobile ? 40 : 120 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: isMobile ? 24 : 72,
            marginRight: isMobile ? 24 : 72,
          }}
        >
          {!isMobile && (
            <div>
              <Title title={genre} style={isMobile ? {} : { fontSize: 32 }} />
            </div>
          )}
          <div style={isMobile ? { width: "100%" } : {}}>
            <Search
              placeholder="学科を検索"
              style={isMobile && { width: "100%" }}
              value={searchedTitle}
              onChange={setSearchedTitle}
            />
          </div>
        </div>
        {isMobile && (
          <div style={{ marginLeft: 24, marginBottom: 6 }}>
            <Title title={genre} />
          </div>
        )}
        <div
          style={{
            display: "flex",
            marginLeft: isMobile ? 16 : 72,
            marginRight: isMobile ? 24 : 72,
          }}
        >
          <div>
            <DropDownList
              options={[
                { label: "講師を選択", value: "" },
                ...instructorsList.map((item) => ({ label: item.full_name, value: item.id })),
              ]}
              values={instructor}
              onChange={setInstructor}
              searchBy="full_name"
              labelField="full_name"
              valueField="id"
              clearable={true}
              placeholder="講師を選択"
            />
          </div>
          <div>
            <DropDownList
              options={[
                { label: "トピックを選択", value: "" },
                ...topicsList.map((item) => ({ label: item.name, value: item.id })),
              ]}
              values={topic}
              onChange={setTopic}
              placeholder="トピックを選択"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            marginTop: isMobile ? 20 : 60,
            marginLeft: isMobile ? 16 : 72,
            marginRight: isMobile ? 16 : 72,
          }}
        >
          {filteredSubjects.map(({ id, thumbnail, title, description }) => (
            <SubjectItem
              isMobile={isMobile}
              title={title}
              description={description}
              thumbnail={thumbnail}
              key={id}
              id={id}
            />
          ))}
        </div>
      </div>
    </>
  );
};
