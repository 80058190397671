import { createSvgIcon } from "@material-ui/core/utils";

export default createSvgIcon(
  <>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0891 4.41076C13.4145 4.7362 13.4145 5.26384 13.0891 5.58928L8.67835 10L13.0891 14.4108C13.4145 14.7362 13.4145 15.2638 13.0891 15.5893C12.7637 15.9147 12.236 15.9147 11.9106 15.5893L6.91058 10.5893C6.58514 10.2638 6.58514 9.7362 6.91058 9.41077L11.9106 4.41076C12.236 4.08533 12.7637 4.08533 13.0891 4.41076Z"
      fill="white"
    />
  </>
);
