// Account Related Pages
import { LoginPage } from "../pages/LoginPage";
// Home Related Pages
import { HomePage } from "../pages/HomePage";
import { FindByDatePage } from "../pages/FindByDatePage";
// Misc Pages
import { EventPage } from "../pages/EventPage";
import { LiveStreamPage } from "../pages/LiveStreamPage";
// Lesson Related Pages
import { SubjectPage } from "../pages/SubjectPage";
import { SubjectListPage } from "../pages/SubjectListPage";
import { LessonPage } from "../pages/LessonPage";
import { VoiceMediaPage } from "../pages/VoiceMediaPage";
import { VoiceMediaListPage } from "../pages/VoiceMediaListPage";
// User Related Pages
import { UserPage } from "../pages/UserPage";
import { CategoryListPage } from "../pages/CategoryListPage";
import { FindUserPage } from "../pages/FindUserPage";
import { MyPage } from "../pages/MyPage";
import { MyPageEdit } from "../pages/MyPageEdit";
// Setting Related Pages
import { SettingsPage, SNSAceessPage } from "../pages/option/SettingsPage";
import { NewsPage, NewsDetailsPage } from "../pages/option/NewsPage";
import { AnnouncementPage, AnnouncementListPage } from "../pages/option/AnnouncementPage";
import { TermsAndConditionsPage } from "../pages/option/TermsAndConditionsPage";
import { PrivacyPolicyPage } from "../pages/option/PrivacyPolicyPage";
import { FindSubjectPage } from "../pages/FindSubjectPage";

export const routing = [
  { path: "/", private: true, exact: true, component: HomePage, paid: true },
  {
    path: "/find_by_date",
    exact: true,
    private: true,
    component: FindByDatePage,
    paid: true,
  },

  // Login/Signup Related Pages {{{
  {
    path: "/login",
    exact: true,
    private: false,
    component: LoginPage,
    paid: false,
  },
  // }}}

  // User Related Pages{{{
  {
    path: "/find_user",
    exact: true,
    private: true,
    component: FindUserPage,
    paid: true,
  },
  {
    path: "/users/:user_id",
    exact: true,
    private: true,
    component: UserPage,
    paid: true,
  },
  {
    path: "/mypage",
    exact: true,
    private: true,
    component: MyPage,
    paid: true,
  },
  {
    path: "/mypage_edit",
    exact: true,
    private: true,
    component: MyPageEdit,
    paid: true,
  },
  // }}}

  // Lesson Related Pages {{{
  {
    path: "/lessons/:id",
    exact: true,
    private: true,
    component: LessonPage,
    paid: true,
  },
  {
    path: "/live_streams/:id",
    exact: true,
    private: true,
    component: LiveStreamPage,
    paid: true,
  },
  {
    path: "/events/:id",
    exact: true,
    private: true,
    component: EventPage,
    paid: true,
  },
  {
    path: "/categories/:categoryId",
    exact: true,
    private: true,
    component: SubjectListPage,
    paid: true,
  },
  {
    path: "/find_subjects",
    exact: true,
    private: true,
    component: FindSubjectPage,
    paid: true,
  },
  {
    path: "/categories",
    exact: true,
    private: true,
    component: CategoryListPage,
    paid: true,
  },
  {
    path: "/subjects/:id",
    exact: true,
    private: true,
    component: SubjectPage,
    paid: true,
  },
  {
    path: "/voice_medium",
    exact: true,
    private: true,
    component: VoiceMediaListPage,
    paid: true,
  },
  {
    path: "/voice_medium/:id",
    exact: true,
    private: true,
    component: VoiceMediaPage,
    paid: true,
  },
  // }}}

  // Setting Related Pages {{{
  {
    path: "/announcements",
    exact: true,
    private: true,
    component: AnnouncementListPage,
    paid: true,
  },
  {
    path: "/announcements/:id",
    exact: true,
    private: true,
    component: AnnouncementPage,
    paid: true,
  },
  {
    path: "/news",
    exact: true,
    private: true,
    component: NewsPage,
    paid: true,
  },
  {
    path: "/news/:id",
    exact: true,
    private: true,
    component: NewsDetailsPage,
    paid: true,
  },
  {
    path: "/settings",
    exact: true,
    private: true,
    component: SettingsPage,
    paid: false,
  },
  {
    path: "/snsacess",
    exact: true,
    private: true,
    component: SNSAceessPage,
    paid: false,
  },
  {
    path: "/privacy_policy",
    exact: true,
    private: false,
    component: PrivacyPolicyPage,
    paid: false,
  },
  {
    path: "/terms_and_conditions",
    exact: true,
    private: false,
    component: TermsAndConditionsPage,
    paid: false,
  },
  // }}}
];
