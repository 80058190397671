import { storage } from "../firebase_exports";
import { useState, useContext, useCallback, useEffect, useRef } from "react";
import { InputBox } from "../components/InputBox";
import { Button } from "../components/Button";
import { HeaderMenu } from "../components/HeaderBar";
import Modal from "@material-ui/core/Modal";
import { DropDownList } from "../components/DropDownList";
import { useHistory } from "react-router-dom";
import { InputArea } from "../components/InputArea";
import { UsersContext, MiscContext } from "../contexts/Contexts";
import { DateInputDropdown } from "../components/DateInputDropdown";
import {
  genderChoices,
  prefectureChoices,
  attributeChoices,
  industryChoices,
} from "../constants/user_choices";
import { mobile, web } from "../constants/colors";
import { AtSymbolIcon } from "@heroicons/react/outline";
import TelImg from "../assets/images/tel.png";
import MailImg from "../assets/images/email.png";
import Resizer from "react-image-file-resizer";
import UserIcon from "../assets/images/blank_profile.png";
import Cropper from "react-easy-crop";
import {
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
  TiktokIcon,
  YoutubeIcon,
} from "../components/SnsIconLink";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

const PublicToggle = ({ value, onChange, isMobile }) => {
  const commonStyle = {
    backgroundColor: "white",
    width: "5em",
    height: 28,
    borderRadius: 3,
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  };
  const publicStyle = {
    ...commonStyle,
    color: "#FFC960",
  };
  const privateStyle = {
    ...commonStyle,
    color: "#373E4D",
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <button style={!value ? publicStyle : privateStyle} onClick={() => onChange(false)}>
          非公開
        </button>
        <button style={value ? publicStyle : privateStyle} onClick={() => onChange(true)}>
          公開
        </button>
      </div>
    </>
  );
};

const iconSize = 58;
const iconStyle = {
  width: `${iconSize}px`,
  borderRadius: 5,
};

const snsLogoSrc = {
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  line: LineIcon,
  tiktok: TiktokIcon,
  youtube: YoutubeIcon,
};

const contactOptions = [
  { label: "電話", value: "phone" },
  { label: "メール", value: "email" },
  { label: "その他", value: "other" },
];

const Space = ({ height = 10 }) => <div style={{ height: height }} />;

export const MyPageEdit = () => {
  const { ownUserInfo, updateOwnUserInfo } = useContext(UsersContext);
  const [image, setImage] = useState({ src: "", title: "" });
  const [file, setFile] = useState(null);
  const { isMobile } = useContext(MiscContext);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [editingImage, setEditingImage] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const imageRef = useRef(null);

  const avatarStyle = {
    width: isMobile ? 79 : 128,
    height: isMobile ? 79 : 128,
    objectFit: "cover",
    position: "relative",
    top: 16,
    bottom: "16",
    marginRight: 16,
    borderRadius: 10,
    border: "0.5px solid gray",
  };
  const contactImgSrc = {
    phone: (
      <img
        alt="tel"
        src={TelImg}
        style={{
          width: 58,
          height: 58,
        }}
      />
    ),
    email: (
      <img
        alt="email"
        src={MailImg}
        style={{
          width: 58,
          height: 58,
        }}
      />
    ),
    other: <AtSymbolIcon />,
  };

  const inputStyle = {
    color: isMobile ? mobile.secondary : web.primary,
    borderRadius: 5,
    border: `1px solid ${isMobile ? mobile.secondary + "55" : web.primary}`,
    width: "100%",
    backgroundColor: "white",
    fontWeight: 700,
  };

  const histroy = useHistory();
  const [editingProfile, setEditingProfile] = useState(ownUserInfo);
  const [interestsString, setInterestsString] = useState("");
  const getPublicFlag = (target) => editingProfile.publics.includes(target);

  useEffect(() => {
    setInterestsString(ownUserInfo.interests.join());
  }, [ownUserInfo]);

  const updatePublicFlag = (target) => (value) => {
    if (value) {
      setEditingProfile({ ...editingProfile, publics: [...editingProfile.publics, target] });
    } else {
      setEditingProfile({
        ...editingProfile,
        publics: editingProfile.publics.filter((e) => e !== target),
      });
    }
  };
  const updateEditProfile = (target) => (value) => {
    if (target === "area" || target === "gender" || target === "industry" || target === "attribute")
      setEditingProfile({ ...editingProfile, [target]: value.value });
    else if (target === "interests") {
      setInterestsString(value);

      setEditingProfile({ ...editingProfile, [target]: value.split(",") });
    } else setEditingProfile({ ...editingProfile, [target]: value });
  };

  const deleteContactData = (target) => () => {
    let tmpContact = editingProfile.contacts;
    delete tmpContact[target];
    setEditingProfile({
      ...editingProfile,
      contacts: tmpContact,
    });
  };

  const deleteSnsData = (target) => () =>
    setEditingProfile({
      ...editingProfile,
      sns_data: { ...editingProfile.sns_data, [target]: "" },
    });

  const handleImageChange = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    // save path is /users/{userid}/avatar
    setImage({ src: URL.createObjectURL(e.target.files[0]), name: e.target.files[0].name });
    setEditingImage(true);
  };

  const uploadImage = async (e) => {
    let resizedImage = await resizeFile(file);
    let storageRef = storage.ref(`/users/${ownUserInfo.id}/avatar`);
    await storageRef.put(resizedImage);
    let url = await storageRef.getDownloadURL();
    // setEditingProfile({ ...editingProfile, avatar: { src: url, title: file.name } });
    updateOwnUserInfo({ ...editingProfile, avatar: { src: url, title: file.name } });
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const decideChanges = async () => {
    if (file) await uploadImage();
    else updateOwnUserInfo(editingProfile);
    histroy.push("/mypage");
  };

  const genderOptions = genderChoices.map((item) => ({ label: item, value: item }));
  const areaOptions = prefectureChoices.map((item) => ({ label: item, value: item }));
  const attributeOptions = attributeChoices.map((item) => ({ label: item, value: item }));
  const industryOptions = Object.entries(industryChoices).map(([key, value]) => ({
    label: key,
    options: value.map((item) => ({ label: item, value: item })),
  }));

  const [isOpenContactCreation, setIsOpenContactCreation] = useState(false);
  const [contactInput, setContactInput] = useState({ name: "", value: "", type: "" });
  const [isOpenSnsCreation, setIsOpenSnsCreation] = useState(false);
  const [snsInput, setSnsInput] = useState({ name: "", username: "" });
  const restSns = ["instagram", "facebook", "twitter", "line", "youtube", "tiktok"].filter(
    (e) => !editingProfile.sns_data[e]
  );

  const Title = ({ style, children }) => (
    <div style={{ color: isMobile ? mobile.secondary : web.primary, fontWeight: 700, ...style }}>
      {children}
    </div>
  );

  const onCropComplete = useCallback((croppedArea, croppedAreaPxls) => {
    setCroppedAreaPixels(croppedAreaPxls);
  }, []);

  const getCroppedImage = () => {
    const img = document.getElementById("profileImg");
    const canvas = document.createElement("canvas");
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      img,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    let newImg = canvas.toDataURL();
    return newImg;
  };
  const handleCropComplete = () => {
    // Create a new file and set it to the file
    // Create a new image and set it to image src
    const croppedImage = getCroppedImage();
    const newFile = dataURLtoFile(croppedImage, "profileimg");
    setFile(newFile);
    setImage({ ...image, src: croppedImage });
    setEditingImage(false);
  };

  return (
    <>
      <div
        style={
          isMobile
            ? { marginBottom: 16 }
            : { marginTop: 100, marginLeft: "10%", marginRight: "10%", width: "80%" }
        }
      >
        <header>
          <HeaderMenu title={"編集"} />
        </header>
        {editingImage && (
          <div>
            <Cropper
              style={{ containerStyle: { zIndex: 100, backgroundColor: "black" } }}
              image={image.src}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              aspect={1}
            />
            <Button
              style={{
                zIndex: 300,
                position: "relative",
                left: "30%",
                width: "40%",
                right: "30%",
              }}
              onClick={() => handleCropComplete()}
              label="確定"
            />
          </div>
        )}
        <div style={{ marginLeft: 24, marginRight: 24, paddingTop: 56 }}>
          <div style={{ backgroundColor: "white", borderRadius: 10, padding: 10, width: "100%" }}>
            <div style={{ width: "100%" }}>
              <div style={{ marginBottom: 20 }}>
                <img
                  id="profileImg"
                  style={avatarStyle}
                  alt={"profile"}
                  src={image.src || editingProfile.avatar?.src || UserIcon}
                  onClick={(e) => imageRef.current.click()}
                />
                <input
                  ref={imageRef}
                  style={{ visibility: "hidden" }}
                  type="file"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                />
              </div>

              <Title>名前</Title>
              <Space />
              <div
                className="name-kanji"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ width: "48%" }}>
                  <Title>姓</Title>
                  <InputBox
                    style={inputStyle}
                    onChange={updateEditProfile("family_name")}
                    value={editingProfile.family_name}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <Title>名</Title>
                  <InputBox
                    style={inputStyle}
                    onChange={updateEditProfile("given_name")}
                    value={editingProfile.given_name}
                  />
                </div>
              </div>
              <div
                className="name-kana"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ width: "48%" }}>
                  <Title>せい</Title>
                  <InputBox
                    style={inputStyle}
                    onChange={updateEditProfile("family_name_kana")}
                    value={editingProfile.family_name_kana}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <Title>めい</Title>
                  <InputBox
                    style={inputStyle}
                    onChange={updateEditProfile("given_name_kana")}
                    value={editingProfile.given_name_kana}
                  />
                </div>
              </div>
            </div>
            <Space />
            <div>
              <Title>業界</Title>
              <DropDownList
                light
                width="98%"
                options={industryOptions}
                defaultValue={{
                  label: editingProfile.industry,
                  value: editingProfile.industry,
                }}
                value={{ label: editingProfile.industry, value: editingProfile.industry }}
                onChange={updateEditProfile("industry")}
              />
            </div>
            <Space />
            <div>
              <Title>属性</Title>
              <DropDownList
                light
                width="98%"
                options={attributeOptions}
                defaultValue={{
                  label: editingProfile.attribute,
                  value: editingProfile.attribute,
                }}
                value={{ label: editingProfile.attribute, value: editingProfile.attribute }}
                onChange={updateEditProfile("attribute")}
              />
            </div>
          </div>
          <div style={{ marginTop: 16 }} className="information">
            <div style={{ display: "grid", gridTemplateColumns: "2fr 6fr 1fr" }}>
              <Title style={{ textAlign: "left" }}>性別</Title>
              <DropDownList
                light
                options={genderOptions}
                defaultValue={{ label: editingProfile.gender, value: editingProfile.gender }}
                value={{ label: editingProfile.gender, value: editingProfile.gender }}
                onChange={updateEditProfile("gender")}
                styles={{ height: "1em" }}
              />
              <PublicToggle
                value={getPublicFlag("gender")}
                onChange={updatePublicFlag("gender")}
                isMobile={isMobile}
              />
            </div>
            <Space />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 6fr 1fr",
                marginTop: 10,
              }}
            >
              <Title style={{ textAlign: "left" }}>
                <span style={{ display: "inline-block" }}>生年</span>
                <span style={{ display: "inline-block" }}>月日</span>
              </Title>
              <DateInputDropdown
                date={editingProfile.birthdate}
                setDate={updateEditProfile("birthdate")}
              />
              <PublicToggle
                value={getPublicFlag("birthdate")}
                onChange={updatePublicFlag("birthdate")}
                isMobile={isMobile}
              />
            </div>
            <Space />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 6fr 1fr",
                marginTop: 10,
              }}
            >
              <Title>拠点</Title>
              <DropDownList
                light
                options={areaOptions}
                isMobile={isMobile}
                defaultValue={{ label: editingProfile.area, value: editingProfile.area }}
                value={{ label: editingProfile.area, value: editingProfile.area }}
                onChange={updateEditProfile("area")}
                styles={{ height: "1em" }}
              />
              <PublicToggle
                value={getPublicFlag("area")}
                onChange={updatePublicFlag("area")}
                isMobile={isMobile}
              />
            </div>
            <Space />
            <div style={{ marginTop: 10 }}>
              <div style={{ display: "grid", gridTemplateColumns: "2fr 6fr 1fr" }}>
                <Title>
                  <span style={{ display: "inline-block" }}>自己</span>
                  <span style={{ display: "inline-block" }}>紹介</span>
                </Title>
                <div></div>
                <PublicToggle
                  value={getPublicFlag("introduction")}
                  onChange={updatePublicFlag("introduction")}
                  isMobile={isMobile}
                />
              </div>
              <InputArea
                style={{
                  ...inputStyle,
                  padding: 10,
                  marginTop: 10,
                  backgroundColor: "white",
                }}
                value={editingProfile.introduction}
                onChange={updateEditProfile("introduction")}
                placeholder="自己紹介"
              />
            </div>
            <Space />
            <div style={{ marginTop: 10 }}>
              <Title>興味</Title>
              <InputBox
                style={inputStyle}
                value={interestsString}
                onChange={updateEditProfile("interests")}
              />
            </div>
            <Space />
            <div style={{ marginTop: 10 }}>
              <Title>連絡先</Title>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {Object.keys(editingProfile.contacts).map((key) => (
                  <div style={{ marginLeft: 12, marginRight: 12 }}>
                    <div
                      onClick={deleteContactData(key)}
                      style={{
                        position: "relative",
                        top: 10,
                        left: -5,
                        fontWeight: 900,
                        textAlign: "center",
                        color: "white",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        border: "1px solid white",
                        width: 16,
                        height: 16,
                        fontSize: 10,
                      }}
                    >
                      ×
                    </div>
                    <div
                      onClick={() => {
                        setContactInput({ [key]: editingProfile.contacts[key] });
                        setIsOpenContactCreation(true);
                      }}
                      style={{
                        borderRadius: 10,
                        width: iconStyle.width,
                        height: iconStyle.width,
                        fontSize: 36,
                        fontWeight: 700,
                        cursor: "pointer",
                      }}
                    >
                      {contactImgSrc[editingProfile.contacts[key].type]}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {key}
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    marginTop: 16,
                    borderRadius: 10,
                    backgroundColor: "white",
                    border: `1px solid ${mobile.secondary}55`,
                    textAlign: "center",
                    width: iconStyle.width,
                    height: iconStyle.width,
                    fontSize: 36,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                  onClick={() => setIsOpenContactCreation(true)}
                >
                  +
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <Title>その他SNS</Title>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {Object.keys(editingProfile.sns_data).map(
                  (key) =>
                    editingProfile.sns_data[key] && (
                      <div style={{ marginLeft: 12, marginRight: 12 }}>
                        <div
                          onClick={deleteSnsData(key)}
                          style={{
                            position: "relative",
                            top: 10,
                            left: -5,
                            fontWeight: 900,
                            textAlign: "center",
                            color: "white",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "1px solid white",
                            width: 16,
                            height: 16,
                            fontSize: 10,
                          }}
                        >
                          ×
                        </div>
                        <img
                          src={snsLogoSrc[key]}
                          style={iconStyle}
                          onClick={deleteSnsData(key)}
                          alt={key}
                        />
                      </div>
                    )
                )}
                {restSns.length !== 0 ? (
                  <div
                    style={{
                      marginTop: 16,
                      borderRadius: 10,
                      backgroundColor: "white",
                      border: `1px solid ${mobile.secondary}55`,
                      textAlign: "center",
                      width: iconStyle.width,
                      height: iconStyle.width,
                      fontSize: 36,
                      fontWeight: 700,
                      cursor: "pointer",
                    }}
                    onClick={() => setIsOpenSnsCreation(true)}
                  >
                    +
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button onClick={decideChanges} style={{ width: "80%" }} label="確定" />
        </div>
        <Modal open={isOpenContactCreation}>
          <div
            style={{
              height: "30%",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 10,
              paddingBottom: 20,
              paddingTop: 20,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <DropDownList
              options={contactOptions}
              values={[contactInput.type]}
              onChange={(item) => {
                setContactInput({ ...contactInput, type: item.value });
              }}
            />
            <InputBox
              style={{ width: "80%" }}
              value={contactInput.name}
              onChange={(value) => setContactInput({ ...contactInput, name: value })}
              placeholder="名称"
            />
            <InputBox
              style={{ width: "80%" }}
              value={contactInput.value}
              onChange={(value) => setContactInput({ ...contactInput, value: value })}
              placeholder="連絡先"
            />
            <Button
              onClick={() => {
                updateEditProfile("contacts")({
                  ...editingProfile.contacts,
                  [contactInput.name]: { type: contactInput.type, value: contactInput.value },
                });
                setContactInput({ name: "", value: "", type: "" });
                setIsOpenContactCreation(false);
              }}
              label="追加"
            />
          </div>
        </Modal>
        <Modal open={isOpenSnsCreation}>
          <div
            style={{
              height: "30%",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 10,
              paddingBottom: 20,
              paddingTop: 20,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <DropDownList
              options={restSns.map((e) => ({ value: e, label: e }))}
              values={[snsInput.name]}
              onChange={(item) => setSnsInput({ ...snsInput, name: item.value })}
            />
            <InputBox
              style={{ width: "80%" }}
              value={snsInput.value}
              onChange={(value) => setSnsInput({ ...snsInput, username: value })}
              placeholder={
                snsInput.name === "youtube"
                  ? "リンクを入力して下さい。"
                  : "ユーザー名を入力して下さい。"
              }
            />
            <Button
              onClick={() => {
                setEditingProfile({
                  ...editingProfile,
                  sns_data: { ...editingProfile.sns_data, [snsInput.name]: snsInput.username },
                });
                console.debug(snsInput, editingProfile);
                setIsOpenSnsCreation(false);
              }}
              label="追加"
            />
          </div>
        </Modal>
      </div>
    </>
  );
};
