import { useContext, useState, useEffect } from "react";
import Parser from "html-react-parser";
import { useHistory, useParams } from "react-router-dom";
import { HeaderMenu } from "../components/HeaderBar";
import { Title } from "../components/Title";
import { LessonAccessModal } from "../components/LessonModal";
import { AuthContext, MiscContext, LessonsContext } from "../contexts/Contexts";
import { mobile } from "../constants/colors";
import LockIcon from "@material-ui/icons/Lock";

const Instructor = ({ name, avatar, description = "", isMobile, external_link = "" }) => {
  const [readMore, setReadMore] = useState(false);
  const imgSize = {
    width: isMobile ? 155 : 310,
    height: isMobile ? 88 : 176,
  };
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
        <img
          alt={avatar.name}
          style={{
            ...imgSize,
            zIndex: -2,
            borderRadius: 3,
            objectFit: "cover",
          }}
          src={avatar.src}
        />
        <div
          style={{
            position: "relative",
            width: imgSize.width,
            top: -imgSize.height * 0.4,
            height: imgSize.height * 0.4,
            background: "linear-gradient(0deg, #000000FF 0%, #00000000 80%)",
            zIndex: 0,
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          marginLeft: isMobile ? imgSize.width * 0.7 : imgSize.width * 0.7,
          marginTop: isMobile ? imgSize.height * 0.8 : imgSize.height * 0.83,
          color: "white",
          fontSize: isMobile ? 10 : 18,
          textAlign: "right",
        }}
      >
        {name}
      </div>
      <div
        style={{
          width: isMobile ? 155 : 440,
          marginLeft: isMobile ? 13 : 60,
        }}
      >
        <div
          style={{
            fontSize: isMobile ? 8 : 14,
            backgroundColor: "#00000000",
            maxHeight: !readMore && imgSize.height,
            overflow: "hidden",
          }}
        >
          {Parser('<div style="background-color: #00000000;">' + description + "</div>", {
            p: { backgroundColor: "#00000000" },
          })}
          {external_link && (
            <div>
              <a
                rel="noreferrer"
                target="_blank"
                style={{ color: "red", fontSize: isMobile ? 8 : 14 }}
                href={external_link}
              >
                もっと詳しく
              </a>
              <br />
            </div>
          )}
        </div>
        <div
          style={{ cursor: "pointer", fontSize: isMobile ? 8 : 14, textDecoration: "underline" }}
          onClick={() => setReadMore(!readMore)}
        >
          {readMore ? "閉じる" : "もっと読む"}
        </div>
      </div>
    </div>
  );
};

const LessonItem = ({
  onClick = () => {},
  first = false,
  last = false,
  triable = false,
  passed = false,
  isMobile,
  thumbnail,
  title,
  description,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }} onClick={onClick}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginRight: isMobile ? 13 : 39,
        }}
      >
        <div
          style={{
            backgroundColor: first ? mobile.primary + "00" : triable ? mobile.primary : "#c4c4c4",
            width: 4,
            height: "50%",
            margin: 0,
            padding: 0,
          }}
        />
        <div
          style={{
            color: mobile.primary,
            borderRadius: "50%",
            border: `${isMobile ? "7px" : "10px"} solid ${triable ? mobile.primary : "#C4C4C4"}`,
          }}
        />
        <div
          style={{
            backgroundColor: last ? "#00000000" : passed ? mobile.primary : "#C4C4C4",
            width: 4,
            height: "50%",
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <img
          alt={title}
          src={thumbnail}
          style={{
            width: isMobile ? 107 : 214,
            height: isMobile ? 61 : 122,
            marginTop: isMobile ? 4 : 16,
            marginBottom: isMobile ? 4 : 16,
            borderRadius: 3,
          }}
        />
        {!triable && (
          <div
            style={{
              position: "absolute",
              width: isMobile ? 107 : 214,
              height: isMobile ? 61 : 122,
              backgroundColor: "#00000099",
              marginTop: isMobile ? 4 : 16,
              borderRadius: 3,
              textAlign: "center",
            }}
          >
            <LockIcon style={{ color: "#FFFFFFBB", marginTop: "20%" }} />
          </div>
        )}
        <div
          style={{
            width: isMobile ? 169 : 420,
            marginLeft: isMobile ? 8 : 45,
            marginTop: isMobile ? 4 : 16,
          }}
        >
          <div style={{ fontWeight: 700, fontSize: isMobile ? 10 : 14 }}>{title}</div>
        </div>
      </div>
    </div>
  );
};

export const SubjectPage = () => {
  const { id } = useParams();
  const [subject, setSubject] = useState({});
  const { getLesson, getSubject, subjectSubscriptions /*, isUserAccessible*/ } =
    useContext(LessonsContext);
  const { isMobile, instructors } = useContext(MiscContext);
  const [lessonsList, setLessonsList] = useState([]);
  const [instructor, setInstructor] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextLessonId, setNextLessonId] = useState("");
  const [noLesson, setNoLesson] = useState(true);
  const { isValidPaidAccount, setShowUpgradeModal } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    const listLessons = async (lessonIds) => {
      lessonIds.sort((a, b) => a.index - b.index);
      let tmp = [];
      let lessonsPromise = new Promise((resolve) => {
        lessonIds.forEach(async (lessonId, index, array) => {
          let lesson = await getLesson(lessonId.id);
          // let triable = await isUserAccessible(lessonId.id); // This does unnecessary request
          let triable = false;
          if (lessonId.index < 1) triable = true; // First video is visible
          if (isValidPaidAccount) {
            if (id in subjectSubscriptions.subjects) {
              if (subjectSubscriptions.subjects[id].index >= lessonId.index) triable = true;
              if (subjectSubscriptions.subjects[id].completed) triable = true;
              setCurrentIndex(subjectSubscriptions.subjects[id].index);
              if (subjectSubscriptions.subjects[id].index in lessonIds) {
                setNextLessonId(lessonIds[subjectSubscriptions.subjects[id].index].id);
              }
            } else {
              setNextLessonId(lessonIds[0].id);
            }
          }
          tmp.push({ ...lesson, triable, index: lessonId.index });
          if (index === array.length - 1) resolve();
        });
      });
      lessonsPromise
        .then(() => {
          setLessonsList(tmp);
        })
        .catch((e) => {
          console.error(e);
          setLessonsList([]);
        });
    };
    const handleGetSubject = async () => {
      getSubject(id).then((item) => {
        setSubject(item);
        setInstructor(instructors[item.instructor_id]);
        if (item.lesson_ids && item.lesson_ids.length > 0) {
          listLessons(item.lesson_ids);
          setNoLesson(false);
        } else {
          setNoLesson(true);
        }
      });
    };
    handleGetSubject();
  }, [id, instructors, getSubject, subjectSubscriptions, getLesson, isValidPaidAccount]);

  const [show, setShow] = useState(false);
  const openModal = () => {
    if (isValidPaidAccount) {
      setShow(true);
    } else {
      setShowUpgradeModal(true);
    }
  };

  return (
    <>
      <LessonAccessModal
        open={show}
        setOpen={setShow}
        isMobile={isMobile}
        nextLesson={nextLessonId}
      />
      <header>
        <HeaderMenu title={subject.title} />
      </header>
      <div
        style={
          isMobile
            ? {
                marginLeft: 24,
                marginRight: 24,
                paddingTop: 42,
              }
            : { marginTop: 108, marginLeft: 24, marginRight: 24 }
        }
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Title
            style={{
              textAlign: "left",
              marginBottom: isMobile ? 8 : 30,
              paddingTop: 20,
              fontSize: !isMobile && 20,
              width: isMobile ? 310 : 660,
            }}
            title={`${subject.title}学科`}
          />
          {instructor && (
            <Instructor
              isMobile={isMobile}
              name={instructor.full_name}
              description={instructor.description}
              avatar={instructor.avatar ? instructor.avatar : { src: "", name: "" }}
              external_link={instructor.exeternal_link}
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {noLesson && <>現在準備中です。</>}
          {lessonsList.map((lesson, i) => (
            <LessonItem
              isMobile={isMobile}
              first={!i}
              triable={lesson.triable}
              passed={currentIndex > lesson.index}
              last={lessonsList.length - 1 === i}
              thumbnail={lesson.thumbnail && lesson.thumbnail.src}
              title={lesson.title}
              description={lesson.description}
              onClick={lesson.triable ? () => history.push(`/lessons/${lesson.id}`) : openModal}
            />
          ))}
        </div>
      </div>
    </>
  );
};
