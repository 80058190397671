import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { User } from "../components/User";
import { PersonalData } from "../components/PersonalData";
import { Hashtag } from "../components/Hashtag";
import { HeaderMenu } from "../components/HeaderBar";
import { SnsIconLink } from "../components/SnsIconLink";
import { AuthContext, MiscContext, UsersContext } from "../contexts/Contexts";
import { userInitialState } from "../constants/user_choices";
import { AtSymbolIcon } from "@heroicons/react/outline";
import TelImg from "../assets/images/tel.png";
import MailImg from "../assets/images/email.png";
import toast from "react-hot-toast";

let sns_list = ["instagram", "facebook", "twitter", "line", "tiktok", "youtube"];

export const UserPage = () => {
  const { isMobile } = useContext(MiscContext);
  const { user_id } = useParams();
  const { fetchUserInfo } = useContext(UsersContext);
  const [user, setUser] = useState(userInitialState);
  const contactImgSrc = {
    phone: (
      <img
        alt="tel"
        src={TelImg}
        style={{
          width: isMobile ? 58 : 80,
          height: isMobile ? 58 : 80,
        }}
      />
    ),
    email: (
      <img
        alt="email"
        src={MailImg}
        style={{
          width: isMobile ? 58 : 80,
          height: isMobile ? 58 : 80,
        }}
      />
    ),
    other: <AtSymbolIcon />,
  };
  useEffect(() => {
    fetchUserInfo(user_id).then((res) => {
      console.debug(user_id, res);
      setUser(res);
    });
  }, [user_id, fetchUserInfo]);

  const { loggedIn, isValidPaidAccount, setShowUpgradeModal } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (loggedIn && !isValidPaidAccount) {
      setShowUpgradeModal(true);
      history.push("/");
    }
  }, [loggedIn, isValidPaidAccount, setShowUpgradeModal, history]);
  return (
    <>
      {!user ? (
        <></>
      ) : (
        <>
          <header>
            <HeaderMenu title={`${user.family_name} ${user.given_name}`} link="/search_user" />
          </header>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ marginTop: isMobile ? 50 : 120 }}>
                <User
                  isMobile={isMobile}
                  avatar={user.avatar}
                  name={`${user.family_name} ${user.given_name}`}
                  industry={user.industry}
                  attribute={user.attribute}
                />
              </div>
            </div>
            <div style={{ width: isMobile ? 327 : 654 }}>
              <div style={{ marginLeft: 24, marginTop: 16, marginRight: 24 }}>
                <PersonalData
                  isMobile={isMobile}
                  sex={user.publics?.includes("gender") ? user.gender : "未公開"}
                  birthday={user.publics?.includes("birthday") ? user.birthday : "未公開"}
                  hub={user.publics?.includes("area") ? user.area : "未公開"}
                  Selfintroduction={
                    user.publics?.includes("introduction") ? user.introduction : "未公開"
                  }
                />
              </div>
              <div style={{ marginLeft: 24, marginTop: 8, marginRight: 24 }}>
                <div style={{ fontSize: isMobile ? 12 : 16, marginBottom: isMobile ? 0 : 20 }}>
                  興味
                </div>
                <div style={{ display: "flex" }}>
                  {user.interests &&
                    user.interests.map((item) => {
                      return (
                        <div style={{ textAlign: "center", marginTop: 2, marginRight: 3 }}>
                          <Hashtag value={item} isMobile={isMobile} />
                        </div>
                      );
                    })}
                </div>
              </div>
              <div style={{ marginLeft: 24, marginTop: 8 }}>
                <div style={{ fontSize: isMobile ? 12 : 16, marginBottom: isMobile ? 0 : 20 }}>
                  連絡先
                </div>
                <div style={{ display: "flex", marginLeft: -4 }}>
                  {Object.keys(user.contacts ?? {}).map((key) => {
                    return (
                      <div
                        onClick={() => {
                          navigator.clipboard
                            .writeText(user.contacts[key].value)
                            .then(() => toast.success("コピーしました"));
                        }}
                        style={{
                          borderRadius: 10,
                          width: isMobile ? 58 : 80,
                          height: isMobile ? 58 : 80,
                          marginLeft: isMobile ? 10 : 7,
                          fontSize: 36,
                          fontWeight: 700,
                          cursor: "pointer",
                        }}
                      >
                        {contactImgSrc[user.contacts[key].type]}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div style={{ marginLeft: 24, marginTop: 4 }}>
                <div style={{ fontSize: isMobile ? 12 : 16, marginBottom: isMobile ? 0 : 20 }}>
                  その他SNS
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {sns_list.map((sns_name) => {
                    return user.sns_data[sns_name] ? (
                      <SnsIconLink
                        sns={sns_name}
                        style={{ width: isMobile ? 58 : 80 }}
                        username={user.sns_data[sns_name]}
                      />
                    ) : null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
